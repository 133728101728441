import omit from 'lodash/omit';
import qs, { stringify } from 'query-string';
import React, { FC, PropsWithChildren, useMemo } from 'react';
import { AuthProvider } from 'react-oidc-context';
import { useNavigate, Location } from 'react-router-dom';

import { UiEmpty } from 'ant/components/ui/empty';
import { useLocalStorage } from 'ant/helpers/hooks/use-local-storage';
import { SettingsAuthModel } from 'ant/types/models/settings.model';
import { AuthServiceProps } from '~/typings/auth';

import { AuthOIDC } from './Auth@oidc';

/**
 * Не заменять history.replace из react-router 5 на нативный history.replaceState, будут проблемы с редиректом
 * Нативный replaceState игнорируется react-router в ряде случаев (см. B2BCORE-5057)
 */
export const getRedirectParams = (): Pick<Location, 'pathname' | 'search'> => {
  const userParamsUrl = stringify(omit(qs.parse(window.location.search), ['state', 'session_state', 'code'])); // TODO хак из офф документации, надо бы создать issue в github чтобы они сами удаляли параметры, потому что редирект может быть с нужными парамерами от пользователя
  const search = userParamsUrl ? `?${userParamsUrl}` : '';

  return {
    pathname: window.location.pathname,
    search,
  };
};

export const AuthServiceOIDC: FC<PropsWithChildren<AuthServiceProps>> = (props) => {
  const [authData] = useLocalStorage<SettingsAuthModel | null>('settingsAuthData', null);
  const navigate = useNavigate();

  if (!authData) {
    return <UiEmpty.Feed emptyMessage={{ header: 'Произошла ошибка авторизации' }} />;
  }

  const oidcConfig = useMemo(() => {
    const { NODE_ENV, REMOTE_URL } = process.env;
    const { clientId, realm, uri, domain } = authData;
    const authDomain = REMOTE_URL && NODE_ENV === 'development' ? REMOTE_URL : domain;

    return {
      authority: `${authDomain}${uri}realms/${realm}`,
      client_id: clientId || '',
      redirect_uri: window.location.href || '',
      silent_redirect_uri: `${window.location.origin}/silent-check-sso.html`,
      onSigninCallback: () => {
        const redirectParams = getRedirectParams();

        navigate(redirectParams, { replace: true });
      },
    };
  }, [authData]);

  return (
    <AuthProvider {...oidcConfig}>
      <AuthOIDC {...props} />
    </AuthProvider>
  );
};

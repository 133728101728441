import { useComponentRegistry } from '@bem-react/di';
import React, { FC, PropsWithChildren } from 'react';

import { Layout } from 'ant/components/layout/layout';
import { PortaledBreadcrumbHTMLElementId } from 'ant/components/widgets/PortaledBreadcrumb';

export const CommonLayoutDesktop: FC<PropsWithChildren> = React.memo(({ children }) => {
  const { Header, Navbar } = useComponentRegistry('pages-frontend');

  return (
    <Layout header={Header && <Header />} navbar={Navbar && <Navbar />}>
      <div id={PortaledBreadcrumbHTMLElementId} />
      {children}
    </Layout>
  );
});

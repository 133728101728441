import React, { useMemo, FC } from 'react';
import { useParams } from 'react-router-dom';

import { UiList } from 'ant/components/ui/list';
import { UiSpace } from 'ant/components/ui/space';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography, UiTypographySymbolName } from 'ant/components/ui/typography';
import { UiTypographySymbolProps } from 'ant/components/ui/typography/symbol/UiTypographySymbol';
import { RouterLink } from 'ant/components/widgets/RouterLink';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { getFormattedDate } from 'ant/plugins/utils/get-formatted-date';
import { useCurrentProfile } from 'ant/providers/current-profile';
import { KeycloakId } from 'ant/types/models/keycloak-user';
import { ProfileInfoModel } from 'ant/types/models/profile.model';

import { InfoEducation } from '../education/InfoEducation';

const NBSP = '\u00A0';

type Props = {
  userData: ProfileInfoModel;
};

export const InfoBiography: FC<Props> = (props) => {
  const profile = useCurrentProfile();
  const { id: userId = '' } = useParams<{ id: KeycloakId }>();

  const isCurrentProfile = userId === profile.id;

  const { userData } = props;
  const { biography, main, additionalInfo, jobHistory } = userData;

  const { cars, devices } = additionalInfo;
  const { metroStation, townLive, birthDayMonth, birthYear, isBirthYearHidden } = main;
  const { about, hobbies, languages, education } = biography;

  const SpaceDivider: FC<{ name?: UiTypographySymbolProps['name'] }> = ({
    name = UiTypographySymbolName.Bullet,
  }) => (
    <>
      <UiTypography.Symbol name={UiTypographySymbolName.NBSP} />
      <UiTypography.Symbol name={name} />
      <UiTypography.Symbol name={UiTypographySymbolName.NBSP} />
    </>
  );

  const birthDayContent = birthDayMonth && (
    <UiTypography.Text strong>
      {getFormattedDate(birthDayMonth)}
      {!isBirthYearHidden && Boolean(birthYear) && (
        <>
          <UiTypography.Symbol name={UiTypographySymbolName.NBSP} />
          {birthYear}
        </>
      )}
    </UiTypography.Text>
  );

  const townContent = townLive && (
    <UiSpace wrap={false} size={0}>
      <UiTypography.Text strong>
        г.
        <UiTypography.Symbol name={UiTypographySymbolName.NBSP} />
        {townLive}
      </UiTypography.Text>

      {metroStation && (
        <UiTypography.Text strong>
          <SpaceDivider />
          м.
          <UiTypography.Symbol name={UiTypographySymbolName.NBSP} />
          {metroStation}
        </UiTypography.Text>
      )}
    </UiSpace>
  );

  const educationContent = useMemo(() => {
    const infoEducation = <InfoEducation education={education} filterByVisible />;
    const isNull = Boolean(infoEducation.type(infoEducation.props) === null);

    return !isNull ? infoEducation : null;
  }, [education]);

  const jobHistoryContent = useMemo(
    () =>
      jobHistory?.length > 0 &&
      jobHistory.map(({ beginDate, endDate, organization, position: jobHistoryPosition }) => (
        <UiTruncateMarkup truncate lines={20} key={`${beginDate}${organization}${endDate}`}>
          <UiTruncateMarkup.Atom>
            <UiTypography.Text>
              {beginDate}
              <SpaceDivider name={UiTypographySymbolName.EmDash} />
              {endDate}
              <SpaceDivider />
            </UiTypography.Text>
            <UiTypography.Text strong>{organization}</UiTypography.Text>
            {jobHistoryPosition && (
              <UiTypography.Text>
                <SpaceDivider name={UiTypographySymbolName.EmDash} />
                {jobHistoryPosition.name}
              </UiTypography.Text>
            )}
          </UiTruncateMarkup.Atom>
        </UiTruncateMarkup>
      )),
    [jobHistory],
  );

  const languagesContent = useMemo(
    () =>
      languages?.length > 0 && (
        <UiTypography.Text strong>
          {languages
            .map(({ languageName, levelName }) =>
              levelName ? `${languageName}${NBSP}—${NBSP}${levelName}` : `${languageName}`,
            )
            .join(` • `)}
        </UiTypography.Text>
      ),
    [languages],
  );

  const hobbiesContent = useMemo(
    () =>
      hobbies?.length > 0 && (
        <UiTypography.Text strong>
          <UiTruncateMarkup truncate lines={20}>
            {hobbies.map(({ name }) => name).join(`${NBSP}• `)}
          </UiTruncateMarkup>
        </UiTypography.Text>
      ),
    [hobbies],
  );

  const devicesContent = useMemo(
    () =>
      devices?.length > 0 && (
        <UiTypography.Text strong>
          <UiTruncateMarkup truncate lines={5}>
            {devices.map(({ device }) => device).join(`${NBSP}• `)}
          </UiTruncateMarkup>
        </UiTypography.Text>
      ),
    [devices],
  );

  const carsContent = useMemo(
    () =>
      cars?.length > 0 &&
      cars.map(({ car, carNumber, id }) => (
        <span key={id}>
          {car && <UiTypography.Text strong>{car}</UiTypography.Text>}
          {car && carNumber && `${NBSP}• `}
          {carNumber && <UiTypography.Text>{carNumber}</UiTypography.Text>}
        </span>
      )),
    [cars],
  );

  const bioItems = [
    {
      label: 'День рождения',
      content: birthDayContent,
    },
    {
      label: 'Проживаю в',
      content: townContent,
    },
    {
      label: 'Образование',
      content: educationContent,
    },
    {
      label: 'Прежние места работы',
      content: jobHistoryContent,
    },
    {
      label: 'Языки',
      content: languagesContent,
    },
    {
      label: 'Хобби',
      content: hobbiesContent,
    },
    {
      label: 'Устройства',
      content: devicesContent,
    },
    {
      label: 'Транспортные средства',
      content: carsContent,
    },
  ];

  return (
    <UiSpace size={12} direction="vertical">
      <UiTypography.Title level={3} style={{ margin: 0 }}>
        Обо мне
      </UiTypography.Title>

      {about && (
        <UiTypography.Text>
          <UiTruncateMarkup truncate lines={20}>
            {about}
          </UiTruncateMarkup>
        </UiTypography.Text>
      )}

      {!about && isCurrentProfile && (
        <UiTypography.Text type="secondary">
          <RouterLink to={getRoutePath(RouteNames.ProfileEdit, { id: userId })}>Расскажите о себе</RouterLink>
          <UiTypography.Symbol name={UiTypographySymbolName.NBSP} />
          <UiTypography.Symbol name={UiTypographySymbolName.EmDash} /> помогите коллегам узнать вас получше
        </UiTypography.Text>
      )}

      <UiList
        grid={{ gutter: 0, column: 1 }}
        dataSource={bioItems}
        renderItem={({ label, content }) =>
          content && (
            <UiList.Item style={{ marginBottom: 12 }}>
              <UiSpace direction="vertical" size={0}>
                <UiTypography.Text type="secondary">{label}</UiTypography.Text>
                {content}
              </UiSpace>
            </UiList.Item>
          )
        }
      />
    </UiSpace>
  );
};

import React from 'react';

import { DynamicRenderComponent } from '~/components/layouts/component/dynamic-render-component/DynamicRenderComponent';
import { CommonLayout } from '~/components/layouts/mail/common/CommonLayout';

export const PageProfileFiles = () => (
  <CommonLayout>
    <CommonLayout.PaddedBlock>
      <DynamicRenderComponent modulePath="ProfileFilesPage" />
    </CommonLayout.PaddedBlock>
  </CommonLayout>
);

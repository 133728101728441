import { useStore } from 'effector-react';
import React, { useMemo, useState, FC } from 'react';
import { useNavigate } from 'react-router-dom';

import CoinSvg from 'ant/components/svg/coin.svg';
import UserAddSvg from 'ant/components/svg/user-add.svg';
import UserCheckedSvg from 'ant/components/svg/user-checked.svg';
import UserCleanSvg from 'ant/components/svg/user-clear.svg';
import { UiButton, UiButtonProps } from 'ant/components/ui/button';
import { UiIcon, UiIconProps } from 'ant/components/ui/icon';
import { message } from 'ant/components/ui/message';
import { UiSpace } from 'ant/components/ui/space';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { coinsDeclension, declension } from 'ant/plugins/declension';
import { getErrorResponseMessage } from 'ant/plugins/get-error-response-message';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { useCurrentProfile } from 'ant/providers/current-profile';
import { useTheme } from 'ant/theme/provider';
import { SubscribedEvent } from 'profile-frontend/components/profile/Profile';
import {
  getTimelinesFollowingIsSubscribedStorage,
  followSubscribedUserEffect,
} from 'profile-frontend/store/timelines';

type Props = {
  userId: string;
  coinsBalance: number;
  toggleCoinsHistory: () => void;
  toggleAddThankOpen: () => void;
  isUserActive: boolean;
};

const { storage: timelinesFollowingIsSubscribedStorage } = getTimelinesFollowingIsSubscribedStorage();
const buttonProps: UiButtonProps = { block: true, size: 'large' };

export const ProfileActions: FC<Props> = (props) => {
  const { coinsBalance, toggleCoinsHistory, userId, toggleAddThankOpen, isUserActive } = props;

  const [{ variables: themeVariables }] = useTheme();
  const profile = useCurrentProfile();
  const navigate = useNavigate();
  const [isSubscribeButtonFocused, setIsSubscribeButtonFocused] = useState(false);
  const { data: followingIsSubscribed, loading: followingIsSubscribedLoading } = useAbstractStorage(
    timelinesFollowingIsSubscribedStorage,
    {
      autoFetchAndRefetch: true,
      autoFetchParams: { ids: [userId] },
    },
  );

  const followSubscribedUserEffectPending = useStore(followSubscribedUserEffect.pending);
  const isSubscribeButtonLoading = followSubscribedUserEffectPending || followingIsSubscribedLoading;
  const isCurrentProfile = profile.id === userId;

  const isFollower = useMemo(
    () => followingIsSubscribed[0]?.isActive || false,
    [userId, followingIsSubscribed],
  );
  const coinsLabel = `${coinsBalance} ${declension(coinsBalance, coinsDeclension)}`;
  const onEditClick = () => navigate(getRoutePath(RouteNames.ProfileEdit, { id: userId }));
  const toggleSubscribeButtonFocus = () => setIsSubscribeButtonFocused((value) => !value);
  const onSubscribeClick = () => {
    followSubscribedUserEffect({
      userId,
      type: isFollower ? SubscribedEvent.Remove : SubscribedEvent.Add,
    }).catch((e) => message.error(getErrorResponseMessage(e, 'Произошла ошибка')));
  };

  const subscribeLabel = useMemo<{
    label: UiButtonProps['label'];
    icon: UiIconProps['component'];
  }>(() => {
    let label = 'Подписаться';
    let icon = UserAddSvg;

    if (isFollower && isSubscribeButtonFocused) {
      label = 'Отписаться';
      icon = UserCleanSvg;
    }

    if (isFollower && !isSubscribeButtonFocused) {
      label = 'Вы подписаны';
      icon = UserCheckedSvg;
    }

    return { label, icon };
  }, [isFollower, isSubscribeButtonFocused]);

  return (
    <UiSpace flex direction="vertical" size={12}>
      {(isCurrentProfile || profile.superAdmin) && (
        <UiButton {...buttonProps} type="secondary" label="Редактировать" onClick={onEditClick} />
      )}
      {isCurrentProfile && (
        <UiButton
          {...buttonProps}
          type="tertiary"
          label={coinsLabel}
          onClick={toggleCoinsHistory}
          icon={<UiIcon component={CoinSvg} fill={themeVariables.colorBrand} width={20} height={20} />}
        />
      )}

      {!isCurrentProfile && (
        <>
          <UiButton
            {...buttonProps}
            type={isFollower ? 'tertiary' : 'primary'}
            onClick={onSubscribeClick}
            onMouseEnter={toggleSubscribeButtonFocus}
            onMouseLeave={toggleSubscribeButtonFocus}
            label={subscribeLabel.label}
            loading={isSubscribeButtonLoading}
            disabled={!followingIsSubscribed[0]?.isActive && !isUserActive}
            style={{ cursor: 'default' }}
          />
          <UiButton
            {...buttonProps}
            type={isUserActive ? 'secondary' : 'primary'}
            onClick={toggleAddThankOpen}
            label="Поблагодарить"
            disabled={!isUserActive}
            style={{ cursor: 'default' }}
          />
        </>
      )}
    </UiSpace>
  );
};

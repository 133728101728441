import { useStore } from 'effector-react';
import isEqual from 'lodash/isEqual';
import React, { useEffect, FC, PropsWithChildren } from 'react';
import { useAuth, hasAuthParams } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

import { Layout } from 'ant/components/layout/layout';
import { UiButton } from 'ant/components/ui/button';
import { UiEmpty } from 'ant/components/ui/empty';
import { useLocalStorage } from 'ant/helpers/hooks/use-local-storage';
import { authService } from 'ant/plugins/auth-service';
import {
  notificationEventListenersGlobal,
  setupNotificationsService,
  useNotificationEventListenersGlobal,
} from 'ant/plugins/notificationsService';
import { settingsAuthConfigStorage } from 'ant/store/settings';
import { SettingsAuthModel } from 'ant/types/models/settings.model';
import { AuthServiceProps } from '~/typings/auth';

import { getRedirectParams } from './AuthService@oidc';

const setAuthorizationCookie = (token?: string) => {
  document.cookie = `Authorization=${token}; path=/;`; // FIXME potential exploit
};

export const AuthOIDC: FC<PropsWithChildren<AuthServiceProps>> = (props) => {
  const { children, setUserId } = props;
  const navigate = useNavigate();

  const auth = useAuth();
  const userId = auth.user?.profile?.sub;

  const [settingsAuthConfigCached] = useLocalStorage<SettingsAuthModel | null>('settingsAuthData', null);
  const { data: settingsAuthConfigData } = useStore(settingsAuthConfigStorage.storage.store);

  const onRedirect = () => {
    const redirectParams = getRedirectParams();

    navigate(redirectParams, { replace: true });
    navigate(0);
  };

  useEffect(() => {
    if (
      settingsAuthConfigData &&
      settingsAuthConfigCached &&
      !isEqual(settingsAuthConfigCached, settingsAuthConfigData)
    ) {
      authService.logout();
    }
  }, [settingsAuthConfigData, settingsAuthConfigCached]);

  useNotificationEventListenersGlobal(notificationEventListenersGlobal);

  useEffect(() => {
    if (auth.user?.access_token) {
      setAuthorizationCookie(auth.user.access_token);
      authService.init(auth);
      setupNotificationsService();
    }
  }, [auth.user?.access_token]);

  const isAutoSignIn = !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading;

  useEffect(() => {
    setUserId(userId);
  }, [userId]);

  useEffect(() => {
    if (!hasAuthParams() && isAutoSignIn) {
      auth.signinRedirect();
    }
  }, [isAutoSignIn, auth.signinRedirect]);

  if (auth.error) {
    return (
      <UiEmpty.Feed
        emptyMessage={{
          header: 'Произошла ошибка авторизации',
          description: <UiButton label="Обновить" type="link" onClick={onRedirect} />,
        }}
      />
    );
  }

  if (auth.isLoading || !auth.isAuthenticated || !userId) {
    return <Layout.Loading />;
  }

  return <>{children}</>;
};

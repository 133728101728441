import { Registry } from '@bem-react/di';

// TODO проверить работоспособность профиля
// import { ProfilePage } from '~/components/layouts/mail/profile/PageProfile';
import { ProfilePage } from 'profile-frontend/components/ProfilePage@default';
import { HeaderDefault } from '~/components/header/Header@default';
import { CommonLayoutDesktopDefault } from '~/components/layouts/mail/common/desktop/CommonLayoutDesktop@default';
import { PageNewsDefault } from '~/components/layouts/mail/news/PageNews@default';
import { PageSearchDefault } from '~/components/layouts/mail/search/PageSearch@default';
import { PageStructureDefault } from '~/components/layouts/mail/structure/PageStructure@default';
import { Navbar } from '~/components/navbar/Navbar';
import { AuthServiceOIDC } from '~/components/routes/auth/AuthService@oidc';
import { Router } from '~/components/routes/router@default/Router';
import { PagesFrontendRegistry } from '~/typings/registry';

export const pagesFrontendDefaultRegistry = new Registry({ id: 'pages-frontend' });

pagesFrontendDefaultRegistry.set<PagesFrontendRegistry['Navbar']>('Navbar', Navbar);
pagesFrontendDefaultRegistry.set<PagesFrontendRegistry['Header']>('Header', HeaderDefault);
pagesFrontendDefaultRegistry.set<PagesFrontendRegistry['CommonLayoutDesktop']>(
  'CommonLayoutDesktop',
  CommonLayoutDesktopDefault,
);
pagesFrontendDefaultRegistry.set<PagesFrontendRegistry['ProfilePage']>('ProfilePage', ProfilePage);
pagesFrontendDefaultRegistry.set<PagesFrontendRegistry['PageNews']>('PageNews', PageNewsDefault);
pagesFrontendDefaultRegistry.set<PagesFrontendRegistry['PageSearch']>('PageSearch', PageSearchDefault);
pagesFrontendDefaultRegistry.set<PagesFrontendRegistry['Router']>('Router', Router);
pagesFrontendDefaultRegistry.set<PagesFrontendRegistry['AuthService']>('AuthService', AuthServiceOIDC);
pagesFrontendDefaultRegistry.set<PagesFrontendRegistry['PageStructure']>(
  'PageStructure',
  PageStructureDefault,
);

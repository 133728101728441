import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { UiDivider } from 'ant/components/ui/divider';
import { UiSpace } from 'ant/components/ui/space';
import { useCurrentProfile } from 'ant/providers/current-profile';
import { GetProfileStructureInfoStorage } from 'ant/store/profile';
import { KeycloakId } from 'ant/types/models/keycloak-user';
import { ProfileInfoModel } from 'ant/types/models/profile.model';
import { UserModelId } from 'ant/types/models/user.model';
import { GetProfileSubdivisionsHeadStorage } from 'profile-frontend/store/profile';

import { InfoBiography } from './biography/InfoBiography';
import { InfoConfidential } from './confidential/InfoConfidential';
import { InfoStructure } from './structure/InfoStructure';

type ProfileInfoProps = {
  userData: ProfileInfoModel;
  profileSubdivisionsHeadStorage: GetProfileSubdivisionsHeadStorage;
  profileStructureInfoStorage: GetProfileStructureInfoStorage;
};

export const ProfileInfo: FC<ProfileInfoProps> = (props) => {
  const { userData, profileSubdivisionsHeadStorage, profileStructureInfoStorage } = props;

  const profile = useCurrentProfile();
  const { id } = useParams<{ id: UserModelId<KeycloakId> }>();

  const isCurrentProfile = id === profile.id;
  const isUserActive = Boolean(userData.main?.isActive);

  return (
    <UiSpace size={20} direction="vertical">
      <InfoStructure
        userData={userData}
        profileSubdivisionsHeadStorage={profileSubdivisionsHeadStorage}
        profileStructureInfoStorage={profileStructureInfoStorage}
      />

      {isUserActive && (
        <>
          <UiDivider emptyMargin />
          <InfoBiography userData={userData} />
        </>
      )}

      {isUserActive && isCurrentProfile && (
        <>
          <UiDivider emptyMargin />
          <InfoConfidential userData={userData} />
        </>
      )}
    </UiSpace>
  );
};

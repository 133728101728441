import { createEvent, restore, createStore } from 'effector';

import { GamificationBadges } from 'ant/types/models/gamification.model';

export const getChosenUserBadgeStorage = () => {
  const updateChosenUserBadgeEvent = createEvent<GamificationBadges | null>();
  const chosenUserBadgeStore = restore<GamificationBadges | null>(updateChosenUserBadgeEvent, null);

  return { chosenUserBadgeStore, updateChosenUserBadgeEvent };
};

export enum GamificationModalTypes {
  UserBadge = 'user_badge',
  AllThanks = 'all_thanks',
  AddThanks = 'add_thanks',
  AddBadge = 'add_badge',
}

type GamificationModalStore = {
  isOpen: boolean;
  type: GamificationModalTypes;
};

export type ToggleGamificationModalEvent = Pick<GamificationModalStore, 'type'>;

const DEFAULT_GAMIFICATION_MODAL_STATE = {
  isOpen: false,
  type: GamificationModalTypes.UserBadge,
};

export const getGamificationModalStorage = () => {
  const toggleGamificationModalEvent = createEvent<ToggleGamificationModalEvent>();
  const gamificationModalStore = createStore<GamificationModalStore>(DEFAULT_GAMIFICATION_MODAL_STATE).on(
    toggleGamificationModalEvent,
    ({ isOpen }, { type }) => ({ type, isOpen: !isOpen }),
  );

  return {
    gamificationModalStore,
    toggleGamificationModalEvent,
  };
};

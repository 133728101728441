// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cc879634_common-layout-padded-block{padding:0 20px 20px}@media(max-width: 991px){.cc879634_common-layout-padded-block{padding:0 12px}}", "",{"version":3,"sources":["webpack://./src/components/layouts/mail/common/padded-block/CommonLayoutPaddedBlock.scss","webpack://./../ant/src/assets/scss/mixins/layout.scss"],"names":[],"mappings":"AACA,qCACE,mBAAA,CCMA,yBDPF,qCAII,cAAA,CAAA","sourcesContent":["@import \"./../ant/src/assets/scss/index.scss\";\n.common-layout-padded-block {\n  padding: 0 $widgetMargin $widgetMargin;\n\n  @include layoutLessThenSmall {\n    padding: 0 $spaceS;\n  }\n}\n","/** Меньше минимальной ширины */\n@mixin layoutLessThenExtraSmall {\n  @media (max-width: $layoutXSWidth - 1px) {\n    @content;\n  }\n}\n\n@mixin layoutLessThenSmall {\n  @media (max-width: $layoutSMWidth - 1px) {\n    @content;\n  }\n}\n\n@mixin layoutLessThenMedium {\n  @media (max-width: $layoutMDWidth - 1px) {\n    @content;\n  }\n}\n\n/** Только маленькие экраны */\n@mixin layoutSmallOnly {\n  @media (max-width: $layoutLGWidth - 1px) {\n    @content;\n  }\n}\n\n/** Только средние экраны */\n@mixin layoutLargeOnly {\n  @media (min-width: $layoutLGWidth) and (max-width: $layoutXLWidth - 1px) {\n    @content;\n  }\n}\n\n/** Маленькие и выше */\n@mixin layoutSmall {\n  @content;\n}\n\n/** Средние и выше */\n@mixin layoutMedium {\n  @media screen and (min-width: $layoutMDWidth) {\n    @content;\n  }\n}\n\n/** Большие и выше */\n@mixin layoutLarge {\n  @media screen and (min-width: $layoutLGWidth) {\n    @content;\n  }\n}\n\n/** Очень большие и выше */\n@mixin layoutExtraLarge {\n  @include layoutExtraLargeOnly {\n    @content;\n  }\n}\n\n/** Только очень большие экраны */\n@mixin layoutExtraLargeOnly {\n  @media screen and (min-width: $layoutXLWidth) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"common-layout-padded-block": "cc879634_common-layout-padded-block",
	"commonLayoutPaddedBlock": "cc879634_common-layout-padded-block"
};
export default ___CSS_LOADER_EXPORT___;

import React, { useMemo, FC } from 'react';

import FaqSvg from 'ant/components/svg/faq.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';
import { UiList } from 'ant/components/ui/list';
import { UiSpace } from 'ant/components/ui/space';
import { UiTooltip } from 'ant/components/ui/tooltip';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography, UiTypographySymbolName } from 'ant/components/ui/typography';
import { ProfileInfoModel } from 'ant/types/models/profile.model';

import { InfoEducation } from '../education/InfoEducation';

type ConfidentialItemProps = {
  label: string;
  children: React.ReactNode;
};

type Props = {
  userData: ProfileInfoModel;
};

export const InfoConfidential: FC<Props> = (props) => {
  const { userData } = props;
  const { biography, main } = userData;

  const { actualAddress, eyeColor, footSize, clothesSize, height } = main;
  const { education } = biography;

  const educationContent = useMemo(() => {
    const infoEducation = <InfoEducation education={education} />;
    const isNull = Boolean(infoEducation.type(infoEducation.props) === null);

    return !isNull ? infoEducation : null;
  }, [education]);
  const showInfoConfidential = Boolean(actualAddress || educationContent);
  const externalData = useMemo(() => {
    const data = [];

    if (eyeColor) {
      data.push(`цвет глаз: ${eyeColor.toLocaleLowerCase()}`);
    }

    if (footSize) {
      data.push(`размер ноги: ${footSize}`);
    }

    if (height) {
      data.push(`рост: ${height}`);
    }

    if (clothesSize) {
      data.push(`размер одежды: ${clothesSize}`);
    }

    return data;
  }, [eyeColor, footSize, clothesSize, height]);

  const ConfidentialItem = ({ label, children }: ConfidentialItemProps) => (
    <UiSpace direction="vertical" size={0}>
      <UiTypography.Text type="secondary">{label}</UiTypography.Text>
      {children}
    </UiSpace>
  );

  if (!showInfoConfidential) return null;

  return (
    <UiSpace size={12} direction="vertical">
      <UiSpace size={8}>
        <UiTypography.Title level={3} style={{ margin: 0 }}>
          Скрытые данные
        </UiTypography.Title>
        <UiTooltip title="Этот блок виден только вам и вашему HR">
          <UiButton type="link-secondary" icon={<UiIcon component={FaqSvg} width={20} height={20} />} />
        </UiTooltip>
      </UiSpace>

      {actualAddress && (
        <ConfidentialItem label="Адрес проживания">
          <UiTypography.Text>
            <UiTruncateMarkup truncate>{actualAddress}</UiTruncateMarkup>
          </UiTypography.Text>
        </ConfidentialItem>
      )}

      {educationContent && <ConfidentialItem label="Образование">{educationContent}</ConfidentialItem>}

      {externalData.some(Boolean) && (
        <ConfidentialItem label="Внешние данные">
          <UiList
            split={false}
            grid={{ gutter: 0, column: 1 }}
            dataSource={externalData}
            renderItem={(item) => (
              <UiTypography.Text>
                <UiTypography.Symbol name={UiTypographySymbolName.Bullet} />
                <UiTypography.Symbol name={UiTypographySymbolName.NBSP} />
                {item}
              </UiTypography.Text>
            )}
          />
        </ConfidentialItem>
      )}
    </UiSpace>
  );
};

import { useEffect, useMemo } from 'react';

import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { authService } from 'ant/plugins/auth-service';
import { openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { getUserAgreementsStorage } from 'ant/store/user-agreements';

export const UserAgreementGuard = () => {
  const userAgreementStorage = useMemo(getUserAgreementsStorage, []);
  const { data: userAgreements } = useAbstractStorage(userAgreementStorage.storage, {
    autoFetchAndRefetch: true,
  });

  useEffect(() => {
    if (Boolean(userAgreements.length) && userAgreements[0] && userAgreements[0].consent === null) {
      openGlobalModal(GlobalModalNames.ProfilePreviewUserAgreementCurrent, {
        onClose: () => authService.logout(),
      });
    }
  }, [userAgreements]);

  return null;
};

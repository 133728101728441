import { useStore } from 'effector-react';
import React, { FC, useMemo, useState } from 'react';

import UploadSvg from 'ant/components/svg/upload.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';
import { UiList } from 'ant/components/ui/list';
import { message } from 'ant/components/ui/message';
import { UiModal } from 'ant/components/ui/modals';
import { UiSpace } from 'ant/components/ui/space';
import { UiTypography } from 'ant/components/ui/typography';
import { CropImageArea, CropImageParams, FigureType } from 'ant/components/widgets/CropImageArea';
import { FilePicker } from 'ant/components/widgets/FilePicker/FilePicker';
import { checkFileSizeUploadEffect } from 'ant/helpers/validation/file-size-helper';
import { currentProfileStorage } from 'ant/plugins/current-profile-service';
import { dataUrlToFile } from 'ant/plugins/data-url-to-file';
import { getErrorResponseMessage } from 'ant/plugins/get-error-response-message';
import { getImageDimensions } from 'ant/plugins/get-image-dimensions';
import { getModalStepsForSingleTitle } from 'ant/plugins/utils/get-modal-steps-for-single-title';
import { useCurrentProfile } from 'ant/providers/current-profile';
import { uploadFileStorageAttachmentEffectFactory } from 'ant/store/filestorage';
import { FileStorageApiVersions } from 'ant/store/filestorage/api';
import { profileFullInfoStorage } from 'ant/store/profile';
import { FileUploadAccepts } from 'ant/types/models/file-upload-accepts';
import { FileStorageEntryId, FileToUploadModel } from 'ant/types/models/file.model';
import { uploadProfileUserAvatarStorage } from 'profile-frontend/store/profile';

const MIN_WIDTH = 200;
const MIN_HEIGHT = 300;
const RULES = [
  '• фотография хорошего качества, чёткая, без шумов, крупных пикселей и других искажений;',
  '• ваше лицо хорошо видно и ничто его не заслоняет, в том числе солнцезащитные очки;',
  '• нельзя использовать фотографии с голыми плечами и торсом;',
  '• на итоговой фотографии не должно быть других людей или животных;',
  `• минимальный размер фото ${MIN_WIDTH} × ${MIN_HEIGHT} пикселей;`,
  '• максимальный вес файла 10 мегабайт.',
];

type Props = {
  onClose: () => void;
};

export const AvatarUploadModal: FC<Props> = (props) => {
  const { onClose } = props;
  const { id: userId } = useCurrentProfile();

  const [rectangleSetting, setRectangleSetting] = useState<CropImageParams>();
  const [circleSetting, setCircleSetting] = useState<CropImageParams>();
  const [imageFile, setImageFile] = useState<FileToUploadModel>();
  const [fileId, setFileId] = useState<FileStorageEntryId>();

  const { uploadProfileUserAvatarEffect } = useMemo(() => uploadProfileUserAvatarStorage(), []);
  const uploadAttachmentEffect = useMemo(() => uploadFileStorageAttachmentEffectFactory(), []);
  const uploadAttachmentInProgress = useStore(uploadAttachmentEffect.pending);
  const uploadAvatarInProgress = useStore(uploadProfileUserAvatarEffect.pending);

  const onAddFiles = (files: FileToUploadModel[]) => {
    if (!files.length) {
      return message.error('Файл не был загружен');
    }

    checkFileSizeUploadEffect({ file: files[0].rawFile, maxSize: 10 })
      .then(async () => {
        const { data, filename } = files[0];
        const [{ width, height }, file] = await Promise.all([
          getImageDimensions(data),
          dataUrlToFile(data, filename),
        ]);

        if (width < MIN_WIDTH || height < MIN_HEIGHT) {
          return message.error('Минимальный размер фото 200 × 300 пикселей.');
        }

        setImageFile(files[0]);

        return uploadAttachmentEffect({
          file,
          apiVersion: FileStorageApiVersions.v3,
        }).then(({ storageObject }) => setFileId(storageObject));
      })
      .catch((e) => {
        message.error(getErrorResponseMessage(e, 'Попробуйте загрузить другой файл'));
      });

    return null;
  };

  const onSuccessUpload = (avatar: string) => {
    onClose();
    currentProfileStorage.refetchWithLastParams();
    profileFullInfoStorage.updateAvatarEvent(avatar);
  };

  const onSave = async () => {
    if (userId && fileId && imageFile && rectangleSetting && circleSetting) {
      uploadProfileUserAvatarEffect({
        userId,
        fileId,
        settings: {
          medium: rectangleSetting,
          small: circleSetting,
        },
      })
        .then(onSuccessUpload)
        .catch((e) => {
          message.error(getErrorResponseMessage(e, 'Ошибка загрузки'));
        });
    }
  };

  const rectangleSettings = {
    setSettings: setRectangleSetting,
    aspect: 10 / 15,
    title: 'Область основного фото',
  };

  const circleSettings = { setSettings: setCircleSetting, aspect: 1, title: 'Область миниатюры' };

  const areaProps = {
    minWidth: MIN_WIDTH,
    minHeight: MIN_HEIGHT,
  };

  return (
    <>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle('Фото профиля')} />
      </UiModal.Header>
      <UiModal.Content basePadding>
        <UiTypography.Title level={4}>
          Загрузите свою лучшую фотографию и убедитесь, что она соответствует правилам:
        </UiTypography.Title>

        <UiList
          split={false}
          dataSource={RULES}
          renderItem={(rule) => (
            <UiList.Item noStyle>
              <UiTypography.Paragraph>{rule}</UiTypography.Paragraph>
            </UiList.Item>
          )}
        />

        <FilePicker onAddFiles={onAddFiles} accept={FileUploadAccepts.ImagePngJpg}>
          <UiButton
            block
            size="large"
            type="secondary"
            label="Загрузить фотографию"
            style={{ marginTop: 24 }}
            loading={uploadAttachmentInProgress}
            disabled={uploadAttachmentInProgress || uploadAvatarInProgress}
            icon={<UiIcon component={UploadSvg} width={20} height={20} />}
          />
        </FilePicker>
        {imageFile && (
          <UiSpace wrap={false} size={20}>
            <CropImageArea.Wrapper>
              <CropImageArea.Title title={rectangleSettings.title} />
              <CropImageArea.Figure
                imageFile={imageFile}
                figureSettings={rectangleSettings}
                figureType={FigureType.Rectangle}
                {...areaProps}
              />
            </CropImageArea.Wrapper>
            <CropImageArea.Wrapper>
              <CropImageArea.Title title={circleSettings.title} />
              <CropImageArea.Figure
                imageFile={imageFile}
                figureSettings={circleSettings}
                figureType={FigureType.Circle}
                {...areaProps}
              />
            </CropImageArea.Wrapper>
          </UiSpace>
        )}
      </UiModal.Content>

      <UiModal.Footer>
        <UiModal.Footer.Buttons>
          <UiButton
            size="large"
            type="primary"
            label="Сохранить"
            onClick={onSave}
            loading={uploadAvatarInProgress}
            disabled={!imageFile?.data || uploadAvatarInProgress || uploadAttachmentInProgress}
          />
          <UiButton size="large" type="tertiary" onClick={onClose} disabled={uploadAvatarInProgress}>
            Отмена
          </UiButton>
        </UiModal.Footer.Buttons>
      </UiModal.Footer>
    </>
  );
};

import { RouteNames } from 'ant/plugins/router';
import { PagesMapping } from 'ant/plugins/router/routes-builder';
import { PageAdminAdministration } from '~/components/layouts/mail/admin-administration/AdminAdministration';
import { PageAdminContentManagementSurveyAnalytics } from '~/components/layouts/mail/admin-content-management-analytics/AdminContentManagementAnalyticsPage';
import { PageAdminContentManagementGroupView } from '~/components/layouts/mail/admin-content-management-group-view/AdminContentManagementGroupView';
import { PageAdminContentManagementGroupsPages } from '~/components/layouts/mail/admin-content-management-groups-pages/AdminContentManagementGroupsPages';
import { PageAdminContentManagement } from '~/components/layouts/mail/admin-content-management/AdminContentManagement';
import { PageAdminHealthCheck } from '~/components/layouts/mail/admin-health-check/AdminHealthCheck';
import { PageAdminOrgStructureDefault } from '~/components/layouts/mail/admin-org-structure/AdminOrgStructure@default';
import { PageAdvancedSearch } from '~/components/layouts/mail/advanced-search/PageAdvancedSearch';
import { PageAdvancedSearchFileView } from '~/components/layouts/mail/advanced-search/file-view/PageAdvancedSearchFileView';
import { PageBlogFiles } from '~/components/layouts/mail/blog-files/PageBlogFiles';
import { PageBlogFileView } from '~/components/layouts/mail/blog-files/view/PageBlogFileView';
import { PageBlog } from '~/components/layouts/mail/blog/PageBlog';
import { PageBlogs } from '~/components/layouts/mail/blogs/PageBlogs';
import { PageCalendar } from '~/components/layouts/mail/calendar/PageCalendar';
import { PageCmsView } from '~/components/layouts/mail/cms/PageCmsView';
import { PageDraftIdeas } from '~/components/layouts/mail/draft-ideas/PageDraftIdeas';
import { PageEvents } from '~/components/layouts/mail/events/PageEvents';
import { PageFavorites } from '~/components/layouts/mail/favorites/PageFavorites';
import { PageFiles } from '~/components/layouts/mail/files/PageFiles';
import { PageFileView } from '~/components/layouts/mail/files/view/PageFileView';
import { PageIdea } from '~/components/layouts/mail/ideas/PageIdea';
import { PageIdeas } from '~/components/layouts/mail/ideas/PageIdeas';
import { PageMain } from '~/components/layouts/mail/main/PageMain';
import { PageMeetingRooms } from '~/components/layouts/mail/meeting-rooms/PageMeetingRooms';
import { PageNewsAdapter } from '~/components/layouts/mail/news/PageNewsAdapter';
import { PageProfileEdit } from '~/components/layouts/mail/profile-edit/PageProfileEdit';
import { PageProfileFiles } from '~/components/layouts/mail/profile-files/PageProfileFiles';
import { PageProfileFileView } from '~/components/layouts/mail/profile-files/view/PageProfileFileView';
import { PageProfileRewardsProgress } from '~/components/layouts/mail/profile-rewards-progress/PageProfileRewardsProgress';
import { PageProfile } from '~/components/layouts/mail/profile/PageProfile';
import { PageProjectFiles } from '~/components/layouts/mail/project-files/PageProjectFiles';
import { PageProjectFileView } from '~/components/layouts/mail/project-files/view/PageProjectFileView';
import { PageProjects } from '~/components/layouts/mail/projects/PageProjects';
import { PageSearchAdapter } from '~/components/layouts/mail/search/PageSearchAdapter';
import { PageSignIn } from '~/components/layouts/mail/sign-in/SignInPage';
import { PageStructureAdapter } from '~/components/layouts/mail/structure/PageStructureAdapter';
import { PageAdminSummaryAnalytics } from '~/components/layouts/mail/summary-analytics/PageSummaryAnalytics';
import { PageSurveys } from '~/components/layouts/mail/surveys/PageSurveys';
import { PageProjectView } from '~/components/layouts/mail/tasks-projects-view/PageTasksProjectsView';
import { PageTasks } from '~/components/layouts/mail/tasks/PageTasks';
import { PageVKHRTech } from '~/components/layouts/mail/vkhr-tech/PageVKHRTech';

export const adminPrivatePagesMap: Partial<PagesMapping> = {
  [RouteNames.AdminOrgStructure]: PageAdminOrgStructureDefault,
  [RouteNames.AdminContentManagement]: PageAdminContentManagement,
  [RouteNames.AdminContentManagementGroupsPages]: PageAdminContentManagementGroupsPages,
  [RouteNames.AdminContentManagementGroupView]: PageAdminContentManagementGroupView,
  [RouteNames.AdminContentManagementSurveysAnalyticsView]: PageAdminContentManagementSurveyAnalytics,
  [RouteNames.AdminAdministration]: PageAdminAdministration,
  [RouteNames.AdminHealthCheck]: PageAdminHealthCheck,
  [RouteNames.AdminSummaryAnalytics]: PageAdminSummaryAnalytics,
};

export const pagesMap: Partial<PagesMapping> = {
  [RouteNames.Main]: PageMain,
  [RouteNames.SignIn]: PageSignIn,
  [RouteNames.Search]: PageSearchAdapter,

  // filestorage-frontend
  [RouteNames.Files]: PageFiles,
  [RouteNames.FileView]: PageFileView,

  // advanced-search-frontend
  [RouteNames.AdvancedSearch]: PageAdvancedSearch,
  [RouteNames.AdvancedSearchFileView]: PageAdvancedSearchFileView,

  // calendar-frontend
  [RouteNames.Calendar]: PageCalendar,

  // cms-frontend
  [RouteNames.CmsView]: PageCmsView,

  // groups-frontend
  [RouteNames.Events]: PageEvents,
  [RouteNames.GroupsList]: PageBlogs,
  [RouteNames.GroupView]: PageBlog,
  [RouteNames.GroupFiles]: PageBlogFiles,
  [RouteNames.GroupFileView]: PageBlogFileView,

  // meeting-rooms-frontend
  [RouteNames.MeetingRooms]: PageMeetingRooms,

  // news-frontend
  [RouteNames.MainNews]: PageNewsAdapter,

  // profile-frontend
  [RouteNames.Profile]: PageProfile,
  [RouteNames.ProfileEdit]: PageProfileEdit,
  [RouteNames.ProfileRewardsProgress]: PageProfileRewardsProgress,
  [RouteNames.ProfileFiles]: PageProfileFiles,
  [RouteNames.ProfileFileView]: PageProfileFileView,

  // structure-frontend
  [RouteNames.Structure]: PageStructureAdapter,

  // surveys-frontend
  [RouteNames.Surveys]: PageSurveys,

  // ideas-frontend
  [RouteNames.Idea]: PageIdea,
  [RouteNames.Ideas]: PageIdeas,
  [RouteNames.IdeasDraft]: PageDraftIdeas,

  // tasks-frontend
  [RouteNames.Tasks]: PageTasks,
  [RouteNames.Projects]: PageProjects,
  [RouteNames.ProjectsView]: PageProjectView,
  [RouteNames.ProjectsFiles]: PageProjectFiles,
  [RouteNames.ProjectsFileView]: PageProjectFileView,

  // favorites-frontend
  [RouteNames.Favorites]: PageFavorites,

  // VKHRTech
  [RouteNames.VKHRTech]: PageVKHRTech,
};

import React, { FC } from 'react';

import { UiTypography } from 'ant/components/ui/typography';
import { RouterLink } from 'ant/components/widgets/RouterLink';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { useCurrentProfile } from 'ant/providers/current-profile';
import { GamificationAddedThank } from 'ant/types/models/gamification.model';

type Props = {
  thank: GamificationAddedThank;
};

export const ThanksHeader: FC<Props> = (props) => {
  const { thank } = props;
  const profile = useCurrentProfile();
  const isReceivedThank = profile.id === thank.toUser.id;
  const user = isReceivedThank ? thank?.fromUser : thank?.toUser;

  const profileName = user?.fullName ? getFullNameWithoutPatronymic(user?.fullName) : '';

  return (
    <UiTypography.Text style={{ marginBottom: 20 }}>
      {isReceivedThank && (
        <UiTypography.Text strong style={{ marginRight: 5 }}>
          от
        </UiTypography.Text>
      )}
      <RouterLink to={getRoutePath(RouteNames.Profile, { id: user.id })}>{profileName}</RouterLink>
    </UiTypography.Text>
  );
};

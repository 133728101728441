import React from 'react';

import { DynamicRenderComponent } from '~/components/layouts/component/dynamic-render-component';
import { CommonLayout } from '~/components/layouts/mail/common/CommonLayout';

export const PageProfileFileView = () => (
  <CommonLayout>
    <CommonLayout.PaddedBlock>
      <DynamicRenderComponent modulePath="ProfileFileViewPage" />
    </CommonLayout.PaddedBlock>
  </CommonLayout>
);

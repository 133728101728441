import { endpoints } from 'ant/endpoints';
import { api } from 'ant/plugins/api';
import { KeycloakId } from 'ant/types/models/keycloak-user';

export const timelinesUserSubscribe = (userId: KeycloakId) =>
  api.post({
    url: endpoints.timelines.usersIdFollow(userId),
  });

export const timelinesUserUnsubscribe = (userId: KeycloakId) =>
  api.patch({
    url: endpoints.timelines.usersIdUnfollow(userId),
  });

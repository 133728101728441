import { AxiosResponse } from 'axios';

import { ProfileEndpoints } from 'ant/endpoints/profile';
import { api } from 'ant/plugins/api';
import { ProfileFullInfoParams } from 'ant/store/profile/api';
import { SubstitutionItemForApi } from 'ant/types/substitution';

export type SubstitutionParams = ProfileFullInfoParams & { substitution: SubstitutionItemForApi[] };

export const putProfileSubstitution = <T = unknown>({
  userId,
  ...data
}: SubstitutionParams): Promise<AxiosResponse<T>> =>
  api.put({
    url: ProfileEndpoints.substitution(userId),
    data: data.substitution,
  });

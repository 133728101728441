import { useStore } from 'effector-react';
import React, { FC, useEffect, useMemo } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import { UiRoute } from 'ant/components/ui/breadcrumb';
import { PortaledBreadcrumb } from 'ant/components/widgets/PortaledBreadcrumb';
import { ShareButton } from 'ant/components/widgets/ShareButton';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { getRoutePath, isPathMatchRoute, RouteNames } from 'ant/plugins/router';
import { useCurrentProfile } from 'ant/providers/current-profile';
import { profileFullInfoStorage } from 'ant/store/profile';
import { KeycloakId } from 'ant/types/models/keycloak-user';
import { ProfileInfoModel } from 'ant/types/models/profile.model';

type ProfileBreadcrumbProps = {
  hideShareButton?: boolean;
  breadcrumbs?: UiRoute[];
};

export const ProfileBreadcrumb: FC<ProfileBreadcrumbProps> = (props) => {
  const { hideShareButton, breadcrumbs = [] } = props;
  const navigate = useNavigate();
  const profile = useCurrentProfile();
  const { id: userId = '' } = useParams<{ id: KeycloakId }>();
  const { pathname } = useLocation();

  const isCurrentProfile = profile.id === userId;
  const isEditAvailable = isCurrentProfile || profile.superAdmin;
  const isProfileEdit = isPathMatchRoute(pathname, RouteNames.ProfileEdit);
  const profilePath = getRoutePath(RouteNames.Profile, { id: userId });

  const { data: profileFullInfoData } = useStore(profileFullInfoStorage.storage.store);

  const { main: profileMainData } = profileFullInfoData || ({} as Partial<ProfileInfoModel>);
  const name = profileMainData?.fullName ? getFullNameWithoutPatronymic(profileMainData.fullName) : '';

  useEffect(() => {
    if (isProfileEdit && !isEditAvailable) {
      navigate(profilePath);
    }
  }, [isEditAvailable, isProfileEdit]);

  const routes: UiRoute[] = useMemo(() => {
    const breadcrumbProfileEditRoutes = [
      { path: `${getRoutePath(RouteNames.Main)}`, breadcrumbName: 'Главная' },
      { path: `${profilePath}`, breadcrumbName: isCurrentProfile ? 'Мой профиль' : name },
      { path: '', breadcrumbName: 'Редактирование' },
    ];

    const breadcrumbProfileRoutes = name ? [...breadcrumbs, { path: '', breadcrumbName: name }] : breadcrumbs;

    return isProfileEdit ? breadcrumbProfileEditRoutes : breadcrumbProfileRoutes;
  }, [isProfileEdit, isCurrentProfile, profilePath, name, breadcrumbs]);

  return (
    <PortaledBreadcrumb
      breadcrumbRoutes={routes}
      after={!isProfileEdit && !hideShareButton && <ShareButton label="Поделиться" />}
    />
  );
};

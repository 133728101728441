import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { endpoints } from 'ant/endpoints';
import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { DictPaginated } from 'ant/types/api';
import {
  GamificationAddedThank,
  GamificationBadgeInfo,
  GamificationBadges,
  GamificationBadgesSummary,
  GamificationThanks,
  GamificationThanksBase,
  GamificationThanksSummary,
  GamificationTransactionHistory,
  GamificationWallet,
} from 'ant/types/models/gamification.model';
import { KeycloakId } from 'ant/types/models/keycloak-user';

import { createThanks, CreateThanksParams, sendBadge, SendBadgeParams } from './api';

export type GetGamificationStorageParams = { userId: KeycloakId };

export const getGamificationUserThanksSummaryStorage = ({ userId }: GetGamificationStorageParams) => {
  const storage = abstractStorageFactory<
    GamificationThanksSummary[],
    GamificationThanksSummary[],
    GamificationThanksSummary[]
  >({
    endpointBuilder: () => endpoints.gamification.userIdThanksReceivedSummary(userId),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  const { refetchWithLastParams } = storage;

  const createThanksEffect = createEffect<CreateThanksParams, GamificationAddedThank, AxiosError>((params) =>
    createThanks<GamificationAddedThank>(params).then((response) => {
      refetchWithLastParams();
      return response.data;
    }),
  );

  return { storage, createThanksEffect };
};

export const getGamificationUserBadgesSummaryStorage = ({ userId }: GetGamificationStorageParams) => {
  const storage = abstractStorageFactory<
    GamificationBadgesSummary[],
    GamificationBadgesSummary[],
    GamificationBadgesSummary[]
  >({
    endpointBuilder: () => endpoints.gamification.userIdBadgesSummary(userId),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

const getGamificationUserBadgesStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<GamificationBadges>,
    DictPaginated<GamificationBadges>,
    null,
    GetGamificationStorageParams
  >({
    endpointBuilder: ({ userId }) => endpoints.gamification.userIdBadges(userId),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  const { refetchWithLastParams } = storage;

  const sendBadgeEffect = createEffect<SendBadgeParams, GamificationAddedThank, AxiosError>((params) =>
    sendBadge<GamificationAddedThank>(params).then((response) => {
      refetchWithLastParams();
      return response.data;
    }),
  );

  return { storage, sendBadgeEffect };
};

export const getGamificationUserThanksStorage = ({ userId }: GetGamificationStorageParams) => {
  const storage = abstractStorageFactory<
    DictPaginated<GamificationThanks>,
    DictPaginated<GamificationThanks>,
    null
  >({
    endpointBuilder: () => endpoints.gamification.userIdThanksReceived(userId),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export const getGamificationBadgesStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<GamificationBadgeInfo>,
    GamificationBadgeInfo[],
    GamificationBadgeInfo[]
  >({
    endpointBuilder: () => endpoints.gamification.badges(),
    dataMapper: (data) => data.items,
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export const getGamificationThanksStorage = () => {
  const storage = abstractStorageFactory<
    GamificationThanksBase[],
    GamificationThanksBase[],
    GamificationThanksBase[]
  >({
    endpointBuilder: () => endpoints.gamification.thanks(),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export const getGamificationWalletStorage = ({ userId }: GetGamificationStorageParams) => {
  const storage = abstractStorageFactory<GamificationWallet, GamificationWallet, null>({
    endpointBuilder: () => endpoints.gamification.userIdWallet(userId),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export const getGamificationTransactionHistory = () => {
  const storage = abstractStorageFactory<
    GamificationTransactionHistory[],
    GamificationTransactionHistory[],
    GamificationTransactionHistory[]
  >({
    endpointBuilder: () => endpoints.gamification.transactionHistory(),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export const gamificationUserBadgesStorage = getGamificationUserBadgesStorage();

export type GamificationUserThanksSummaryStorage = ReturnType<typeof getGamificationUserThanksSummaryStorage>;
export type GamificationUserBadgesSummaryStorage = ReturnType<typeof getGamificationUserBadgesSummaryStorage>;
export type GamificationUserBadgesStorage = ReturnType<typeof getGamificationUserBadgesStorage>;
export type GamificationUserThanksStorage = ReturnType<typeof getGamificationUserThanksStorage>;
export type GamificationThanksStorage = ReturnType<typeof getGamificationThanksStorage>;
export type GamificationWalletStorage = ReturnType<typeof getGamificationWalletStorage>;

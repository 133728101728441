import React from 'react';

import { DynamicRenderComponent } from '~/components/layouts/component/dynamic-render-component/DynamicRenderComponent';
import { CommonLayout } from '~/components/layouts/mail/common/CommonLayout';

const ProfileTimeline = () => <DynamicRenderComponent modulePath="ProfileTimeline" />;

export const PageProfile = () => (
  <CommonLayout>
    <DynamicRenderComponent modulePath="ProfilePage" moduleProps={{ ProfileTimeline }} />
  </CommonLayout>
);

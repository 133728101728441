import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { Routes, Route } from 'react-router-dom';

import { Layout } from 'ant/components/layout/layout';
import { ErrorBoundary } from 'ant/components/widgets/ErrorBoundary';
import { ScrollToTopButton } from 'ant/components/widgets/ScrollToTopButton';
import { currentProfileRolesPermissionsMapStore } from 'ant/plugins/current-profile-service';
import { buildRoutesFromConfig } from 'ant/plugins/router';
import { useCurrentProfile } from 'ant/providers/current-profile';
import { GlobalModals } from '~/components/global-modals/GlobalModals';
import { adminPrivatePagesMap, pagesMap } from '~/components/routes/pages';
import { UserAgreementGuard } from '~/components/user-agreement-guard/UserAgreementGuard';

export const Router: FC = () => {
  const currentProfile = useCurrentProfile();
  const currentProfilePermissionsMap = useStore(currentProfileRolesPermissionsMapStore);
  const isAccess = currentProfile.superAdmin || currentProfilePermissionsMap.size > 0;

  return (
    <>
      <ErrorBoundary>
        <Routes>
          {isAccess && buildRoutesFromConfig(adminPrivatePagesMap)}
          {buildRoutesFromConfig(pagesMap)}
          <Route path="*" Component={Layout.NotFound} />
        </Routes>
      </ErrorBoundary>
      <GlobalModals />
      <UserAgreementGuard />
      <ScrollToTopButton />
    </>
  );
};

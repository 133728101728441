import React, { FC, useMemo } from 'react';

import CoinSvg from 'ant/components/svg/coin.svg';
import { UiRow } from 'ant/components/ui/grid';
import { UiIcon } from 'ant/components/ui/icon';
import { UiSkeleton } from 'ant/components/ui/skeleton';
import { UiSpace } from 'ant/components/ui/space';
import { UiTypography, UiTypographyTextTypes } from 'ant/components/ui/typography';
import { RouterLink } from 'ant/components/widgets/RouterLink';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { getFormattedDate } from 'ant/plugins/utils/get-formatted-date';
import { themePalette } from 'ant/theme';
import { useTheme } from 'ant/theme/provider';
import {
  GamificationTransactionHistory,
  GamificationTransactionType,
} from 'ant/types/models/gamification.model';

type Props = {
  transaction: GamificationTransactionHistory;
  onClose: () => void;
};

export const TransactionMock = () => (
  <UiSpace size={8} style={{ padding: '10px 0' }}>
    <UiSkeleton loading height={18} width={18} />
    <UiSkeleton loading height={18} width={300} />
  </UiSpace>
);

export const Transaction: FC<Props> = (props) => {
  const { transaction, onClose } = props;
  const { amount, type, text: gamificationTitle, sender, recipient, createdAt } = transaction;
  const isTransactionType = (transactionType: GamificationTransactionType) => transactionType === type;
  const isRecipientTransfer = isTransactionType(GamificationTransactionType.Transfer) && amount < 0;
  const user = isRecipientTransfer ? recipient : sender;

  const title = useMemo(() => {
    if (isTransactionType(GamificationTransactionType.Transfer)) {
      return amount < 0 ? 'перевод' : 'от';
    }

    if (isTransactionType(GamificationTransactionType.ActionCompletion)) {
      return 'за действие на портале';
    }

    if (isTransactionType(GamificationTransactionType.BadgeCompletion)) {
      return 'за достижение';
    }

    return '';
  }, [transaction]);

  const [{ variables: themeVariables }] = useTheme();
  const labelProps = {
    type: isRecipientTransfer ? UiTypographyTextTypes.Secondary : UiTypographyTextTypes.Primary,
    label: isRecipientTransfer ? `${amount}` : `+${amount}`,
    fill: isRecipientTransfer ? themePalette.colorIcon : themeVariables.colorBrand,
  };

  return (
    <UiRow justify="space-between" align="middle">
      <UiSpace size={4}>
        <UiIcon.Label strong {...labelProps} component={CoinSvg} width={20} height={20} />
        {title && <UiTypography.Text>{title}</UiTypography.Text>}
        {isTransactionType(GamificationTransactionType.BadgeCompletion) && (
          <UiTypography.Text strong>{gamificationTitle}</UiTypography.Text>
        )}
        {isTransactionType(GamificationTransactionType.Transfer) && user.fullName && (
          <RouterLink to={getRoutePath(RouteNames.Profile, { id: user.id })} onClick={onClose} strong>
            {getFullNameWithoutPatronymic(user.fullName)}
          </RouterLink>
        )}
      </UiSpace>

      <UiTypography.Text type="secondary">{getFormattedDate(createdAt, 'dd.MM.yyyy')}</UiTypography.Text>
    </UiRow>
  );
};

import React, { FC, useMemo } from 'react';

import CoinSvg from 'ant/components/svg/coin.svg';
import { UiEmpty } from 'ant/components/ui/empty';
import { UiIcon } from 'ant/components/ui/icon';
import { UiList } from 'ant/components/ui/list';
import { UiModal } from 'ant/components/ui/modals';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { createArrayMock } from 'ant/plugins/create-mocks';
import { getModalStepsForSingleTitle } from 'ant/plugins/utils/get-modal-steps-for-single-title';
import { useTheme } from 'ant/theme/provider';
import { getGamificationTransactionHistory } from 'profile-frontend/store/gamification';

import { Transaction, TransactionMock } from './transaction/Transaction';

type Props = {
  coinsBalance?: number;
  onClose: () => void;
};

const transactionsMocks = createArrayMock(10, (_, id) => <TransactionMock key={id} />);

export const ProfileTransactionsHistory: FC<Props> = (props) => {
  const { coinsBalance = 0, onClose } = props;
  const [{ variables: themeVariables }] = useTheme();
  const transactionHistoryStorage = useMemo(() => getGamificationTransactionHistory(), []);
  const { data: transactions, loading } = useAbstractStorage(transactionHistoryStorage.storage, {
    autoFetchAndRefetch: true,
    resetStoreOnUnmount: true,
  });

  const emptyText = (
    <UiEmpty.Feed emptyMessage={{ header: 'Нет данных', description: 'У вас ещё нет истории начислений.' }} />
  );

  return (
    <>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle('История начислений')}>
          <UiIcon.Label
            width={20}
            height={20}
            component={CoinSvg}
            label={coinsBalance.toString()}
            fill={themeVariables.colorBrand}
            style={{ marginLeft: 'auto' }}
          />
        </UiModal.Header.Title>
      </UiModal.Header>

      <UiModal.Content basePadding>
        {loading && transactionsMocks}
        {!loading && (
          <UiList
            split={false}
            itemLayout="vertical"
            locale={{ emptyText }}
            dataSource={transactions}
            renderItem={(transaction) => (
              <UiList.Item style={{ padding: '10px 0' }}>
                <Transaction transaction={transaction} onClose={onClose} />
              </UiList.Item>
            )}
          />
        )}
      </UiModal.Content>
    </>
  );
};

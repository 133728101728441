import { useStore } from 'effector-react';
import React, { FC, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { UiAvatarSize } from 'ant/components/ui/avatar';
import { UiCard } from 'ant/components/ui/card';
import { UiDivider } from 'ant/components/ui/divider';
import { UiImageBackground } from 'ant/components/ui/image-background';
import { UiList } from 'ant/components/ui/list';
import { UiModal } from 'ant/components/ui/modals';
import { UiModalContext } from 'ant/components/ui/modals/modal/context';
import { UiSkeleton } from 'ant/components/ui/skeleton';
import { UiSpace } from 'ant/components/ui/space';
import { UiTypography } from 'ant/components/ui/typography';
import { UiUserInfo } from 'ant/components/ui/user/info/UiUserInfo';
import { RouterLink } from 'ant/components/widgets/RouterLink';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { createArrayMock } from 'ant/plugins/create-mocks';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { utcToLocalDate } from 'ant/plugins/utils/get-formatted-date';
import { profileFullInfoStorage } from 'ant/store/profile';
import { useTheme } from 'ant/theme/provider';
import { GamificationItemTypes, GamificationThanks } from 'ant/types/models/gamification.model';
import { KeycloakId } from 'ant/types/models/keycloak-user';
import { Gender } from 'ant/types/models/user.model';
import { GamificationTrophy } from 'components-frontend/components/gamification/trophy/GamificationTrophy';
import patternAchievePng from 'profile-frontend/assets/images/pattern-achieve.png';
import { GamificationEmpty } from 'profile-frontend/components/gamification/empty/GamificationEmpty';
import { getGamificationUserThanksStorage } from 'profile-frontend/store/gamification';

const getGenderedActionByType = (type: GamificationItemTypes, gender: Gender) => {
  if (type === GamificationItemTypes.Thanks) {
    return gender === Gender.Female ? 'поблагодарила' : 'поблагодарил';
  }

  if (type === GamificationItemTypes.Badge) {
    return gender === Gender.Female ? 'получила достижение' : 'получил достижение';
  }

  return '';
};

export const GamificationList: FC = () => {
  const { onClose } = useContext(UiModalContext);
  const [{ variables: themeVariables }] = useTheme();
  const { id: userId = '' } = useParams<{ id: KeycloakId }>();

  const userThanksStorage = useMemo(() => getGamificationUserThanksStorage({ userId }), [userId]);
  const { data: userThanks, loading: isUserThanksLoading } = useAbstractStorage(userThanksStorage.storage, {
    autoFetchAndRefetch: true,
    resetStoreOnUnmount: true,
  });

  const { data: profileFullInfoData } = useStore(profileFullInfoStorage.storage.store);
  const titleSteps = { 1: { title: 'Благодарности', badgeValue: userThanks?.meta?.objectsTotal } };

  if (!profileFullInfoData) return null;

  const { main: toUser } = profileFullInfoData;

  const Subtitle: FC<{ thank: GamificationThanks }> = ({ thank }) => {
    const { createdAt, fromUser } = thank;

    return (
      <UiSpace size={4}>
        <UiTypography.Text type="secondary">{utcToLocalDate(createdAt, 'dd MMMM yyyy')}</UiTypography.Text>
        <UiTypography.Text type="secondary" strong>
          {getGenderedActionByType(GamificationItemTypes.Thanks, fromUser.gender)}
        </UiTypography.Text>
        <RouterLink to={getRoutePath(RouteNames.Profile, { id: toUser.id })}>
          {getFullNameWithoutPatronymic(toUser.fullName)}
        </RouterLink>
      </UiSpace>
    );
  };

  return (
    <>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={titleSteps} />
      </UiModal.Header>

      <UiModal.Content basePadding>
        {isUserThanksLoading && (
          <UiList
            grid={{ gutter: 16, column: 1 }}
            dataSource={createArrayMock(4, (_, i) => i)}
            renderItem={() => (
              <UiList.Item noStyle>
                <UiSpace direction="vertical" full>
                  <UiUserInfo.Skeleton size={UiAvatarSize.M} />
                  <UiSkeleton loading width="100%" height={180} />
                </UiSpace>
                <UiDivider overwriteBasePadding />
              </UiList.Item>
            )}
          />
        )}

        {!isUserThanksLoading && !userThanks?.items?.length && (
          <GamificationEmpty toggleAddThanksOpen={onClose} type={GamificationItemTypes.Thanks} />
        )}

        {toUser && !isUserThanksLoading && Boolean(userThanks?.items?.length) && (
          <UiList
            grid={{ gutter: 16, column: 1 }}
            dataSource={userThanks?.items}
            renderItem={(thank) => (
              <UiList.Item noStyle>
                <UiSpace direction="vertical" full size={20}>
                  <UiUserInfo
                    avatar={{ size: 40, src: thank.fromUser.avatar }}
                    title={getFullNameWithoutPatronymic(thank.fromUser.fullName)}
                    to={getRoutePath(RouteNames.Profile, { id: thank.fromUser.id })}
                    subTitle={<Subtitle thank={thank} />}
                  />

                  <UiCard.Content style={{ borderRadius: 4, backgroundColor: themeVariables.colorBrand08 }}>
                    <UiImageBackground url={patternAchievePng} style={{ textAlign: 'center', padding: 20 }}>
                      <GamificationTrophy thank={{ ...thank, toUser }} onClose={onClose} />
                    </UiImageBackground>
                  </UiCard.Content>
                </UiSpace>
                <UiDivider overwriteBasePadding />
              </UiList.Item>
            )}
          />
        )}
      </UiModal.Content>
    </>
  );
};

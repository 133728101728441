import { useComponentRegistry } from '@bem-react/di';
import React, { FC, PropsWithChildren } from 'react';

import { CommonLayoutPaddedBlock } from './padded-block/CommonLayoutPaddedBlock';

type CommonLayoutComposition = {
  PaddedBlock: typeof CommonLayoutPaddedBlock;
};

export const CommonLayout: FC<PropsWithChildren> & CommonLayoutComposition = (props) => {
  const { children } = props;
  const { CommonLayoutDesktop } = useComponentRegistry('pages-frontend');

  return <CommonLayoutDesktop>{children}</CommonLayoutDesktop>;
};

CommonLayout.PaddedBlock = CommonLayoutPaddedBlock;

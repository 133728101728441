import { useStore } from 'effector-react';
import React, { FC, useCallback, useMemo, useState } from 'react';

import QRSvg from 'ant/components/svg/qr.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiCard } from 'ant/components/ui/card';
import { UiIcon } from 'ant/components/ui/icon';
import { UiModal, UiModalTypes } from 'ant/components/ui/modals';
import { UiQRCode } from 'ant/components/ui/qr-code';
import { UiSpace } from 'ant/components/ui/space';
import { UiTypography, UiTypographySymbolName } from 'ant/components/ui/typography';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { getModalStepsForSingleTitle } from 'ant/plugins/utils/get-modal-steps-for-single-title';
import {
  getProfileContactsStorage,
  getProfileStructureInfoStorage,
  profileFullInfoStorage,
} from 'ant/store/profile';
import { useTheme } from 'ant/theme/provider';
import { getVcardProfileContacts } from 'profile-frontend/helpers/get-vcard-profile-contacts';

type QRCardProps = {
  userId: string;
};

export const EmployeeContactsQRCard: FC<QRCardProps> = (props) => {
  const { userId } = props;
  const [{ variables: themeVariables }] = useTheme();
  const [isQrModalOpen, setIsQrModalOpen] = useState(false);
  const profileContactsStorage = useMemo(() => getProfileContactsStorage({ userId }), [userId]);
  const { data: profileFullInfo } = useStore(profileFullInfoStorage.storage.store);
  const { data: contacts } = useAbstractStorage(profileContactsStorage.storage, {
    autoFetchAndRefetch: Boolean(userId),
  });

  const profileStructureInfoStorage = useMemo(getProfileStructureInfoStorage, []);
  const { data: profileStructureInfoData } = useAbstractStorage(profileStructureInfoStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { id: userId },
    cancelPendingRequestOnUnmount: true,
  });

  const QRCodeValue = useMemo(() => {
    if (profileFullInfo && profileStructureInfoData && contacts) {
      return getVcardProfileContacts(profileFullInfo, profileStructureInfoData, contacts);
    }

    return '';
  }, [profileFullInfo, contacts]);
  const modalTitle = useMemo(
    () =>
      profileFullInfo?.main
        ? `${getFullNameWithoutPatronymic(profileFullInfo?.main?.fullName)} — QR-визитка`
        : undefined,
    [profileFullInfo],
  );

  const toggleQrModalOpen = useCallback(() => setIsQrModalOpen((value) => !value), []);

  return (
    <>
      <UiButton.Decorator onClick={toggleQrModalOpen}>
        <UiCard.Footer hasTopBorder>
          <UiSpace direction="vertical">
            <UiIcon.Label component={QRSvg}>
              <UiTypography.Title level={3} style={{ margin: 0, color: themeVariables.colorTextSecondary }}>
                QR-визитка
              </UiTypography.Title>
            </UiIcon.Label>
            <UiTypography.Text type="secondary">
              <UiTypography.Link>Просканируйте</UiTypography.Link>
              <UiTypography.Symbol name={UiTypographySymbolName.NBSP} />
              помощью камеры смартфона, чтобы добавить в контакты
            </UiTypography.Text>
          </UiSpace>
        </UiCard.Footer>
      </UiButton.Decorator>

      <UiModal isOpen={isQrModalOpen} type={UiModalTypes.Small} onClose={toggleQrModalOpen}>
        <UiModal.Header>
          <UiModal.Header.Title steps={getModalStepsForSingleTitle(modalTitle)} />
        </UiModal.Header>
        <UiModal.Content basePadding style={{ paddingTop: 0 }}>
          <UiQRCode level="L" value={QRCodeValue} size={412} includeMargin={false} />
        </UiModal.Content>
      </UiModal>
    </>
  );
};

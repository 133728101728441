import React, { useState, FC, CSSProperties } from 'react';

import { UiModal, UiModalTypes } from 'ant/components/ui/modals';
import { UiOverlay } from 'ant/components/ui/overlay';
import { UiTypography, UiTypographyTextTypes } from 'ant/components/ui/typography';
import { UserAvatar } from 'ant/components/widgets/UserAvatar';
import { authService } from 'ant/plugins/auth-service';
import { UserProfileModel } from 'ant/types/models/user.model';

import { AvatarUploadModal } from './upload-modal/AvatarUploadModal';

type Props = {
  profileMain: UserProfileModel;
};

const containerStyles: CSSProperties = { height: 300 };
const avatarLabelTextStyles: CSSProperties = {
  position: 'absolute',
  left: '50%',
  bottom: '24px',
  transform: 'translateX(-50%)',
};

export const ProfileAvatar: FC<Props> = (props) => {
  const { profileMain } = props;
  const [isAvatarUpdate, setIsAvatarUpdate] = useState(false);

  const { id, isActive: isUserActive } = profileMain;

  const isOwner = Boolean(id && id === authService.getCurrentUserId());
  const isActiveOwner = isOwner && Boolean(isUserActive);

  const onCloseUpdate = () => setIsAvatarUpdate(false);

  const onOpenUpdate = () => {
    if (isOwner) {
      setIsAvatarUpdate(true);
    }
  };

  return (
    <>
      <UiOverlay.Button
        trigger="hover"
        label="Обновить фото"
        onClick={onOpenUpdate}
        isVisible={isActiveOwner}
      >
        <UserAvatar user={profileMain} shape="square" size={200} style={containerStyles} />
        {!profileMain.isActive && (
          <UiTypography.Text type={UiTypographyTextTypes.Invert} style={avatarLabelTextStyles}>
            Заблокирован
          </UiTypography.Text>
        )}
      </UiOverlay.Button>

      {isActiveOwner && (
        <UiModal
          isMaskClosable={false}
          isOpen={isAvatarUpdate}
          type={UiModalTypes.Large}
          onClose={onCloseUpdate}
        >
          <AvatarUploadModal onClose={onCloseUpdate} />
        </UiModal>
      )}
    </>
  );
};

import { useStore } from 'effector-react';
import React, { FC } from 'react';

import { UiList } from 'ant/components/ui/list';
import { UiSpace } from 'ant/components/ui/space';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { UserProfile } from 'ant/components/widgets/UserProfile';
import { declension, subdivisionGenitiveDeclension } from 'ant/plugins/declension';
import { getFullNameWithPatronymic } from 'ant/plugins/name-formatters';
import { getFormattedDate } from 'ant/plugins/utils/get-formatted-date';
import { useSettingsConfig } from 'ant/providers/settings-config';
import { GetProfileStructureInfoStorage } from 'ant/store/profile';
import { ProfileInfoModel } from 'ant/types/models/profile.model';
import { GetProfileSubdivisionsHeadStorage } from 'profile-frontend/store/profile';

type Props = {
  userData: ProfileInfoModel;
  profileSubdivisionsHeadStorage: GetProfileSubdivisionsHeadStorage;
  profileStructureInfoStorage: GetProfileStructureInfoStorage;
};

export const InfoStructure: FC<Props> = (props) => {
  const { config } = useSettingsConfig();
  const { userData, profileSubdivisionsHeadStorage, profileStructureInfoStorage } = props;

  const { data: profileSubdivisionsHeadData } = useStore(profileSubdivisionsHeadStorage.storage.store);
  const { data: profileStructureInfoData } = useStore(profileStructureInfoStorage.storage.store);

  const { organization, division } = profileStructureInfoData || {};
  const { job, main, projects } = userData;

  const { fullName, startOfWork, blockedAt } = main;
  const { position, boss } = job;

  const startOfWorkValue = getFormattedDate(startOfWork, 'dd MMMM yyyy');
  const endOfWorkValue = blockedAt ? getFormattedDate(blockedAt, 'dd MMMM yyyy') : null;

  const subdivisionsContent = Boolean(profileSubdivisionsHeadData.length) && (
    <UiTypography.Text strong>
      <UiTruncateMarkup truncate lines={20}>
        {profileSubdivisionsHeadData.map((subdivision) => subdivision.name).join(' • ')}
      </UiTruncateMarkup>
    </UiTypography.Text>
  );

  const projectsContent = Boolean(projects?.length) && (
    <UiTypography.Text strong>
      <UiTruncateMarkup truncate lines={20}>
        {projects.map(({ project }) => project.name).join(' • ')}
      </UiTruncateMarkup>
    </UiTypography.Text>
  );

  const referencesItems = [
    {
      label: 'Организация',
      content: organization && <UiTypography.Text strong>{organization}</UiTypography.Text>,
    },
    {
      label: 'Подразделение',
      content: division && <UiTypography.Text strong>{division}</UiTypography.Text>,
    },
    {
      label: `Руководитель ${declension(profileSubdivisionsHeadData.length, subdivisionGenitiveDeclension)}`,
      content: subdivisionsContent,
    },
    {
      label: 'Проекты',
      content: projectsContent,
    },
    {
      label: 'Руководители',
      content: boss && (
        <UserProfile.Info
          size={8}
          user={{ ...boss, ...boss.fullName }}
          avatarProps={{ src: boss.avatar, size: 24 }}
          align="middle"
        />
      ),
    },
    {
      label: 'Дата начала работы',
      content: config.layouts?.profileStartOfWorkEnabled?.value && startOfWorkValue && (
        <UiTypography.Text>{startOfWorkValue}</UiTypography.Text>
      ),
    },
    {
      label: 'Дата окончания работы',
      content: endOfWorkValue && <UiTypography.Text>{endOfWorkValue}</UiTypography.Text>,
    },
  ];

  return (
    <UiSpace size={20} direction="vertical">
      <UiSpace size={4} direction="vertical">
        {fullName && (
          <UiTypography.Title level={1} style={{ margin: 0 }}>
            {getFullNameWithPatronymic(fullName)}
          </UiTypography.Title>
        )}
        {position && (
          <UiTypography.Title level={4} type="secondary" style={{ margin: 0 }}>
            {position.name}
          </UiTypography.Title>
        )}
      </UiSpace>

      <UiList
        grid={{ gutter: 0, column: 1 }}
        dataSource={referencesItems}
        renderItem={({ label, content }) =>
          content && (
            <UiList.Item style={{ marginBottom: 12 }}>
              <UiSpace direction="vertical" size={4}>
                <UiTypography.Text type="secondary">{label}</UiTypography.Text>
                {content}
              </UiSpace>
            </UiList.Item>
          )
        }
      />
    </UiSpace>
  );
};

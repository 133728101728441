import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AttachSvg from 'ant/components/svg/attach.svg';
import UserFollowerSvg from 'ant/components/svg/user-follower.svg';
import UserFollowingSvg from 'ant/components/svg/user-following.svg';
import { UiBadge } from 'ant/components/ui/badge';
import { UiCard } from 'ant/components/ui/card';
import { UiDivider } from 'ant/components/ui/divider';
import { UiCol, UiRow } from 'ant/components/ui/grid';
import { UiIcon } from 'ant/components/ui/icon';
import { UiMenu, UiMenuItemType } from 'ant/components/ui/menu';
import { UiModal, UiModalTypes } from 'ant/components/ui/modals';
import { UiSkeleton } from 'ant/components/ui/skeleton';
import { UiSpace } from 'ant/components/ui/space';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { createArrayMock } from 'ant/plugins/create-mocks';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { useCurrentProfile } from 'ant/providers/current-profile';
import { closeGlobalModal, openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { getProfileStructureInfoStorage, profileFullInfoStorage } from 'ant/store/profile';
import { KeycloakId } from 'ant/types/models/keycloak-user';
import { AddThanks } from 'profile-frontend/components/gamification/add-thanks/AddThanks';
import { ProfileActions } from 'profile-frontend/components/profile/actions/ProfileActions';
import { ProfileAvatar } from 'profile-frontend/components/profile/avatar/ProfileAvatar';
import { ProfileInfo } from 'profile-frontend/components/profile/info/ProfileInfo';
import {
  gamificationUserBadgesStorage,
  getGamificationUserThanksSummaryStorage,
  getGamificationWalletStorage,
} from 'profile-frontend/store/gamification';
import { getProfileSubdivisionsHeadStorage } from 'profile-frontend/store/profile';
import { getTimelinesAllRelationsCounterStorage, SocialsInfoType } from 'profile-frontend/store/timelines';

import { FollowList } from './follow-list/FollowList';
import { ProfileTransactionsHistory } from './transactions-history/ProfileTransactionsHistory';

export enum SubscribedEvent {
  Add = 'add',
  Remove = 'remove',
}

const REFERENCES_MOCK_NUMBER = 4;
const ABOUT_MOCK_NUMBER = 7;
const PROFILE_INFO_MOCK = (
  <UiSpace size={20} direction="vertical">
    <UiSpace size={0} direction="vertical">
      <UiSkeleton width={260} height={32} loading />
      <UiSkeleton width={220} height={20} loading />
    </UiSpace>
    <UiSpace size={0} direction="vertical">
      {createArrayMock(REFERENCES_MOCK_NUMBER, (_, key) => (
        <UiSkeleton key={key} loading width={200} height={20} />
      ))}
    </UiSpace>
    <UiSpace size={0} direction="vertical">
      <UiSkeleton loading width={90} height={20} />
    </UiSpace>
    <UiSpace size={0} direction="vertical">
      {createArrayMock(ABOUT_MOCK_NUMBER, (_, key) => (
        <UiSkeleton key={key} loading width={460} height={20} />
      ))}
    </UiSpace>
  </UiSpace>
);

const timelinesAllRelationsCounterStorage = getTimelinesAllRelationsCounterStorage();

export const Profile: FC = () => {
  const navigate = useNavigate();
  const { id = '' } = useParams<{ id: KeycloakId }>();
  const profile = useCurrentProfile();
  const isCurrentProfile = profile.id === id;

  const userThanksStorage = useMemo(() => getGamificationUserThanksSummaryStorage({ userId: id }), [id]);
  const profileSubdivisionsHeadStorage = useMemo(getProfileSubdivisionsHeadStorage, []);
  const profileStructureInfoStorage = useMemo(getProfileStructureInfoStorage, []);

  const { data: profileFullInfoData, loading: isProfileFullInfoLoading } = useAbstractStorage(
    profileFullInfoStorage.storage,
    {
      autoFetchAndRefetch: true,
      autoFetchParams: { userId: id },
    },
  );

  const { data: allRelationsCounterData } = useAbstractStorage(timelinesAllRelationsCounterStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { userId: id },
  });

  const { loading: isProfileSubdivisionsHeadLoading } = useAbstractStorage(
    profileSubdivisionsHeadStorage.storage,
    {
      autoFetchAndRefetch: true,
      autoFetchParams: { userId: id },
      cancelPendingRequestOnUnmount: true,
    },
  );

  const { loading: isProfileStructureInfoLoading } = useAbstractStorage(profileStructureInfoStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { id },
    cancelPendingRequestOnUnmount: true,
  });

  const [isAddThankOpen, setIsAddThankOpen] = useState(false);
  const [showCoinsHistory, setShowCoinsHistory] = useState(false);
  const [showFollowList, setShowFollowList] = useState(false);
  const [socialsInfoType, setSocialsInfoType] = useState<SocialsInfoType>(SocialsInfoType.Followers);
  const isUserActive = Boolean(profileFullInfoData?.main?.isActive);

  const toggleAddThankOpen = useCallback(() => setIsAddThankOpen((value) => !value), []);
  const toggleCoinsHistory = useCallback(() => setShowCoinsHistory((value) => !value), []);
  const toggleFollowList = useCallback(() => setShowFollowList((value) => !value), []);

  useEffect(() => {
    if (isAddThankOpen) {
      const unsubscribe = userThanksStorage.createThanksEffect.doneData.watch((thank) => {
        openGlobalModal(GlobalModalNames.ProfileBadgeThanksView, {
          onClose: () => closeGlobalModal(GlobalModalNames.ProfileBadgeThanksView),
          thank,
        });

        return unsubscribe;
      });
    }
  }, [isAddThankOpen]);

  const userWalletStorage = useMemo(() => getGamificationWalletStorage({ userId: id }), [id]);
  const { data: userWallet } = useAbstractStorage(userWalletStorage.storage, {
    autoFetchAndRefetch: isCurrentProfile,
    resetStoreOnUnmount: true,
  });
  const coinsBalance = userWallet?.balance ? parseInt(userWallet.balance, 10) : 0;

  const setSocialsInfoAction = useCallback((type: SocialsInfoType) => {
    setSocialsInfoType(type);
    toggleFollowList();
  }, []);

  useEffect(() => {
    return gamificationUserBadgesStorage.sendBadgeEffect.done.watch(
      userWalletStorage.storage.refetchWithLastParams,
    );
  }, [userWalletStorage]);

  const profileMenuItems = useMemo<UiMenuItemType[]>(() => {
    const items: UiMenuItemType[] = [
      {
        key: SocialsInfoType.Followers,
        label: 'Подписчики',
        icon: <UiIcon width={20} height={20} component={UserFollowerSvg} />,
        onClick: () => setSocialsInfoAction(SocialsInfoType.Followers),
        extraIcon: <UiBadge count={allRelationsCounterData.followers} />,
        style: { padding: 0 },
      },
      {
        key: SocialsInfoType.Followings,
        label: 'Подписки',
        extraIcon: <UiBadge count={allRelationsCounterData.followings} />,
        icon: <UiIcon width={20} height={20} component={UserFollowingSvg} />,
        onClick: () => setSocialsInfoAction(SocialsInfoType.Followings),
        style: { padding: 0 },
      },
    ];

    if (isCurrentProfile) {
      items.push({
        key: 'FileStorage',
        label: 'Файловое хранилище',
        extraIcon: <UiBadge count={0} />,
        icon: <UiIcon width={20} height={20} component={AttachSvg} />,
        onClick: () => navigate(getRoutePath(RouteNames.ProfileFiles, { id })),
        style: { padding: 0 },
      });
    }

    return items;
  }, [allRelationsCounterData, id]);

  const isProfileFullInfoData = !isProfileFullInfoLoading && profileFullInfoData;
  const isLoading =
    isProfileSubdivisionsHeadLoading || isProfileStructureInfoLoading || isProfileFullInfoLoading;

  return (
    <UiCard size="default" style={{ marginBottom: 20 }}>
      <UiRow wrap={false} gutter={24}>
        <UiCol flex="none">
          {!isProfileFullInfoData && (
            <UiSkeleton loading={isProfileFullInfoLoading} width={200} height={300} />
          )}
          <UiSpace direction="vertical" size={16}>
            {isProfileFullInfoData && <ProfileAvatar profileMain={profileFullInfoData.main} />}

            {!isProfileFullInfoLoading && (
              <ProfileActions
                userId={id}
                coinsBalance={coinsBalance}
                toggleCoinsHistory={toggleCoinsHistory}
                toggleAddThankOpen={toggleAddThankOpen}
                isUserActive={isUserActive}
              />
            )}
            {isUserActive && (
              <>
                <UiDivider emptyMargin />
                <UiMenu isStickyBadge selectable={false} strong items={profileMenuItems} />
              </>
            )}
          </UiSpace>
        </UiCol>
        <UiCol flex="auto">
          {isLoading && PROFILE_INFO_MOCK}
          {!isLoading && profileFullInfoData && (
            <ProfileInfo
              userData={profileFullInfoData}
              profileSubdivisionsHeadStorage={profileSubdivisionsHeadStorage}
              profileStructureInfoStorage={profileStructureInfoStorage}
            />
          )}

          <UiModal type={UiModalTypes.Medium} isOpen={showCoinsHistory} onClose={toggleCoinsHistory}>
            <ProfileTransactionsHistory coinsBalance={coinsBalance} onClose={toggleCoinsHistory} />
          </UiModal>

          <UiModal type={UiModalTypes.Medium} isOpen={isAddThankOpen} onClose={toggleAddThankOpen}>
            <AddThanks userThanksStorage={userThanksStorage} />
          </UiModal>

          <UiModal type={UiModalTypes.Large} isOpen={showFollowList} onClose={toggleFollowList}>
            <FollowList
              type={socialsInfoType}
              onClose={toggleFollowList}
              counterStorage={timelinesAllRelationsCounterStorage}
              userName={profileFullInfoData?.main?.fullName?.firstName}
            />
          </UiModal>
        </UiCol>
      </UiRow>
    </UiCard>
  );
};

import classNames from 'classnames';
import { isWithinInterval, isSameDay } from 'date-fns';
import React, { FC, useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { UiAvatarSize } from 'ant/components/ui/avatar';
import { UiButton } from 'ant/components/ui/button';
import { UiCard } from 'ant/components/ui/card';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { UiUser } from 'ant/components/ui/user';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { authService } from 'ant/plugins/auth-service';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { RouteNames, getRoutePath } from 'ant/plugins/router';
import { SubstitutionModel } from 'ant/types/models/substitution.model';
import { getProfileSubstitutionStorage } from 'profile-frontend/store/substitution';

import styles from './Absence.scss';
import { AbsenceHeader } from './header/AbsenceHeader';

const SHOW_ACTIVITIES_COUNT = 4;

export const enum SubstitutionType {
  Illness = 'illness',
  Vacation = 'vacation',
  BusinessTrip = 'business_trip',
}

export const Absence: FC = () => {
  const [showMore, setShowMore] = useState(true);
  const { id = '' } = useParams<{ id: string }>();
  const currentUserId = authService.getCurrentUserId();
  const userId = id ?? currentUserId;
  const profileSubstitutionStorage = useMemo(() => getProfileSubstitutionStorage({ userId }), [userId]);

  const { data: substitution } = useAbstractStorage(profileSubstitutionStorage.storage, {
    autoFetchAndRefetch: Boolean(userId),
  });

  const activeSubstitution = useMemo(() => {
    return (
      substitution
        .filter((item: SubstitutionModel) => {
          return (
            isSameDay(new Date(item.since), new Date(item.till)) ||
            isWithinInterval(new Date(), { start: new Date(item.since), end: new Date(item.till) })
          );
        })
        .find((item) => {
          return (
            item.type === SubstitutionType.Illness ||
            item.type === SubstitutionType.Vacation ||
            item.type === SubstitutionType.BusinessTrip
          );
        }) ?? null
    );
  }, [substitution]);

  const activities = useMemo(() => {
    return activeSubstitution?.activities?.map((item) => (
      <UiTypography.Text className={styles.absence__listItem} key={item}>
        <UiTruncateMarkup truncate>{item}</UiTruncateMarkup>
      </UiTypography.Text>
    ));
  }, [activeSubstitution]);

  useEffect(() => {
    if (activeSubstitution) {
      setShowMore(SHOW_ACTIVITIES_COUNT >= Number(activeSubstitution.activities?.length));
    }
  }, [activeSubstitution]);

  if (!activeSubstitution) return null;

  return (
    <UiCard size="default" className={styles.absence} emptyPadding>
      <AbsenceHeader substitution={activeSubstitution} />
      <div className={styles.absence__content}>
        <UiTypography.Text className={classNames(styles.absence__text, styles.absence__contentText)}>
          Меня замещают:
        </UiTypography.Text>
        <UiUser.Info
          avatar={{ src: activeSubstitution.substitute.smallAvatar, size: UiAvatarSize.M }}
          title={getFullNameWithoutPatronymic(activeSubstitution.substitute.fullName)}
          to={getRoutePath(RouteNames.Profile, { id: activeSubstitution.substitute.id })}
          subTitle={
            <div className={styles.absence__list}>
              {activities && activities.length > 0 && (
                <>
                  {activities?.slice(0, showMore ? activities.length : SHOW_ACTIVITIES_COUNT)}
                  {!showMore && (
                    <UiButton
                      type="link"
                      label={`Ещё ${activities.length - SHOW_ACTIVITIES_COUNT}`}
                      onClick={() => setShowMore(true)}
                    />
                  )}
                </>
              )}
              {!activities?.length && (
                <UiTypography.Text className={styles.absence__listItem}>по всем вопросам</UiTypography.Text>
              )}
            </div>
          }
        />
      </div>
    </UiCard>
  );
};

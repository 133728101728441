import { useStore } from 'effector-react';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { UiEmpty, UiEmptyFrameProps } from 'ant/components/ui/empty';
import { UiTypography, UiTypographySymbolName } from 'ant/components/ui/typography';
import { useCurrentProfile } from 'ant/providers/current-profile';
import { profileFullInfoStorage } from 'ant/store/profile';
import { GamificationItemTypes } from 'ant/types/models/gamification.model';
import { KeycloakId } from 'ant/types/models/keycloak-user';
import { Gender } from 'ant/types/models/user.model';

const SHOW_OWNER_BADGES = false; // TODO: Раздел в разработке

type GamificationEmptyProps = {
  type: GamificationItemTypes;
  toggleAddThanksOpen?: () => void;
  style?: UiEmptyFrameProps['style'];
};

export const GamificationEmpty: FC<GamificationEmptyProps> = (props) => {
  const { type, toggleAddThanksOpen, style } = props;
  const currentProfile = useCurrentProfile();
  const { id: userId = '' } = useParams<{ id: KeycloakId }>();
  const isOwner = userId === currentProfile.id;

  const { data: profileFullInfoData } = useStore(profileFullInfoStorage.storage.store);
  const isGamificationItemType = (gamificationType: GamificationItemTypes) => gamificationType === type;

  if (!profileFullInfoData) return null;

  const { fullName, gender } = profileFullInfoData.main;
  const gettingWithGender = gender === Gender.Female ? 'получала' : 'получал';

  return (
    <UiEmpty.Frame style={style}>
      <UiTypography.Text type="secondary">
        {isGamificationItemType(GamificationItemTypes.Badge) && (
          <>
            Здесь ещё ничего нет
            {SHOW_OWNER_BADGES && (
              <>
                , но это легко поправить:
                <UiTypography.Symbol name={UiTypographySymbolName.NBSP} />
                <UiTypography.Link>посмотрите</UiTypography.Link>, за что можно получить достижения
              </>
            )}
          </>
        )}

        {isOwner && isGamificationItemType(GamificationItemTypes.Thanks) && 'У вас ещё нет благодарностей'}
        {!isOwner && isGamificationItemType(GamificationItemTypes.Thanks) && (
          <>
            <UiTypography.Text type="secondary">
              {fullName?.firstName} ещё не {gettingWithGender} благодарностей.
            </UiTypography.Text>
            <br />
            <UiTypography.Link onClick={toggleAddThanksOpen}>Поблагодарить</UiTypography.Link>
          </>
        )}
      </UiTypography.Text>
    </UiEmpty.Frame>
  );
};

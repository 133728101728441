import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { ProfileEndpoints } from 'ant/endpoints/profile';
import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { UserIdParams } from 'ant/store/profile';
import { SubstitutionModel } from 'ant/types/models/substitution.model';

import { putProfileSubstitution, SubstitutionParams } from './api';

export const getProfileSubstitutionStorage = ({ userId }: UserIdParams) => {
  const storage = abstractStorageFactory<SubstitutionModel, SubstitutionModel[], SubstitutionModel[]>({
    endpointBuilder: () => ProfileEndpoints.substitution(userId),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  const putProfileSubstitutionEffect = createEffect<SubstitutionParams, SubstitutionModel[], AxiosError>(
    (params) => putProfileSubstitution<SubstitutionModel[]>(params).then(({ data }) => data),
  );

  storage.store.on(putProfileSubstitutionEffect.doneData, (state, substitution) =>
    state.data
      ? {
          ...state,
          data: substitution,
        }
      : state,
  );

  return { storage, putProfileSubstitutionEffect };
};

import { useStore } from 'effector-react';
import React, { useCallback, useMemo, FC } from 'react';
import { useParams } from 'react-router-dom';

import AddCircleIcon from 'ant/components/svg/add-circle.svg';
import RemoveCircleIcon from 'ant/components/svg/remove-circle.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiEmpty } from 'ant/components/ui/empty';
import { UiIcon } from 'ant/components/ui/icon';
import { UiLinkListItem } from 'ant/components/ui/link-list-item';
import { UiList } from 'ant/components/ui/list';
import { message } from 'ant/components/ui/message';
import { UiModal } from 'ant/components/ui/modals';
import { UiRender, UiRenderType } from 'ant/components/ui/render';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { authService } from 'ant/plugins/auth-service';
import { createArrayMock } from 'ant/plugins/create-mocks';
import { getErrorResponseMessage } from 'ant/plugins/get-error-response-message';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { getModalStepsForSingleTitle } from 'ant/plugins/utils/get-modal-steps-for-single-title';
import {
  GetTimelinesAllRelationsCounterStorage,
  getFollowListStorage,
  SocialsInfoType,
} from 'profile-frontend/store/timelines';
import { FollowUser } from 'profile-frontend/typings/follow-user';

import { SubscribedEvent } from '../Profile';

const MOCKS_COUNT = 3;
const PAGE_SIZE = 10;
const usersMocks = createArrayMock(MOCKS_COUNT, (_, key) => <UiLinkListItem.Skeleton key={key} />);

type Props = {
  userName?: string;
  type: SocialsInfoType;
  onClose: () => void;
  counterStorage: GetTimelinesAllRelationsCounterStorage;
};

export const FollowList: FC<Props> = (props) => {
  const { type, onClose, counterStorage, userName } = props;
  const ownerId = authService.getCurrentUserId();
  const { id = '' } = useParams<{ id: string }>();
  const { followingsCountEvent } = counterStorage;
  const {
    storage: followListStorage,
    followUserEffect,
    unfollowUserEffect,
  } = useMemo(getFollowListStorage, []);

  const {
    data: followListData,
    loading: isFollowListLoading,
    pagination: followListPagination,
    fetchFx: fetchFeed,
  } = useAbstractStorage(followListStorage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { pageNumber: 1, pageSize: PAGE_SIZE, userId: id, type },
    resetStoreOnUnmount: true,
    cancelPendingRequestOnUnmount: true,
  });

  const isFollowUserEffectPending = useStore(followUserEffect.pending);
  const isUnfollowUserEffectPending = useStore(unfollowUserEffect.pending);
  const counterStore = useStore(counterStorage.storage.store);

  const isSubscribeButtonLoading = isFollowUserEffectPending || isUnfollowUserEffectPending;
  const isFollowListMocks = isFollowListLoading && !followListData.length;
  const followListCount = useMemo(
    () => followListData.filter(({ isSubscribed }) => isSubscribed).length,
    [followListData],
  );

  const followsEmptyMessage = useMemo(() => {
    const descriptionType = type === SocialsInfoType.Followers ? 'подписчиков' : 'подписок';

    return {
      header: 'Нет данных',
      description:
        id === ownerId
          ? `У вас нет ${descriptionType}`
          : `У пользователя ${userName} пока нет ${descriptionType}`,
    };
  }, [id, ownerId, type]);

  const onSubscribeClick = (followUser: FollowUser) => {
    const subscribeEffect = followUser.isSubscribed ? unfollowUserEffect : followUserEffect;

    subscribeEffect(followUser).catch((e) => message.error(getErrorResponseMessage(e, 'Произошла ошибка')));

    if (id === ownerId) {
      followingsCountEvent({ type: followUser.isSubscribed ? SubscribedEvent.Remove : SubscribedEvent.Add });
    }
  };

  const onClickFetchFeeds = useCallback(
    () =>
      followListPagination.page
        ? fetchFeed({
            pageNumber: followListPagination.page + 1,
            pageSize: PAGE_SIZE,
            userId: id,
            type,
          })
        : null,
    [followListPagination],
  );

  const hasMore =
    followListPagination.page &&
    followListPagination.count &&
    followListPagination.page < followListPagination.count;
  const SubscribeButton: FC<{ followUser: FollowUser }> = (btnProps) => {
    const { followUser } = btnProps;

    return (
      <UiRender type={UiRenderType.DisplayNone} visible={followUser.id !== ownerId}>
        <UiButton
          style={{ marginRight: 24 }}
          type={followUser.isSubscribed ? 'link-secondary' : 'link'}
          onClick={() => onSubscribeClick(followUser)}
          disabled={isSubscribeButtonLoading}
          label={followUser.isSubscribed ? 'Отписаться' : 'Подписаться'}
          icon={
            <UiIcon
              width={20}
              height={20}
              component={followUser.isSubscribed ? RemoveCircleIcon : AddCircleIcon}
            />
          }
        />
      </UiRender>
    );
  };

  return (
    <>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title
          steps={getModalStepsForSingleTitle(
            type === SocialsInfoType.Followers ? 'Подписчики' : 'Подписки',
            !isFollowListLoading && type === SocialsInfoType.Followers
              ? counterStore.data?.followers
              : followListCount,
          )}
        />
      </UiModal.Header>
      {/* TODO: Реализовать после B2BCORE-2844 */}
      {/* <UiModal.Content basePadding style={{ flex: 'none' }}> */}
      {/*  <UiInput.Search size="large" placeholder="Поиск" /> */}
      {/* </UiModal.Content> */}

      <UiModal.Content>
        {isFollowListMocks && usersMocks}
        {!isFollowListMocks && (
          <UiList
            split={false}
            locale={{ emptyText: <UiEmpty.Feed emptyMessage={followsEmptyMessage} /> }}
            rowKey={(user) => user.id}
            dataSource={followListData}
            renderItem={(user) => (
              <UiList.Item noStyle>
                <UiLinkListItem
                  onClick={onClose}
                  avatar={{ src: user.avatar }}
                  to={getRoutePath(RouteNames.Profile, { id: user.id })}
                  suffixedChildren={<SubscribeButton followUser={user} />}
                  title={getFullNameWithoutPatronymic({ lastName: user.lastName, firstName: user.firstName })}
                  subTitle={user.position?.name || ''}
                />
              </UiList.Item>
            )}
          />
        )}

        {hasMore && (
          <UiButton
            block
            size="large"
            type="secondary"
            label="Загрузить ещё"
            loading={isFollowListLoading}
            disabled={isFollowListLoading}
            onClick={onClickFetchFeeds}
          />
        )}
      </UiModal.Content>
    </>
  );
};

import { isSameDay, isSameMonth } from 'date-fns';
import React, { FC, useMemo } from 'react';

import SickBig from 'ant/components/svg/sick-big.svg';
import TripBig from 'ant/components/svg/trip-big.svg';
import VacationBig from 'ant/components/svg/vacation-big.svg';
import { UiRow } from 'ant/components/ui/grid';
import { UiIcon } from 'ant/components/ui/icon';
import { UiSpace } from 'ant/components/ui/space';
import { UiTypography } from 'ant/components/ui/typography';
import { getFormattedDate, getValidDate } from 'ant/plugins/utils/get-formatted-date';
import { themePalette } from 'ant/theme';
import { SubstitutionModel } from 'ant/types/models/substitution.model';

import { SubstitutionType } from '../Absence';

type Substitution = {
  icon: SvgrComponent;
  text: string;
  backgroundColor: string;
};

type Props = {
  substitution: SubstitutionModel;
};

const AbsenceHeader: FC<Props> = ({ substitution }) => {
  const renderSubstitutionType: Record<string, Substitution> = {
    [SubstitutionType.Illness]: {
      icon: SickBig,
      text: 'На больничном',
      backgroundColor: themePalette.colorAccidentGold,
    },
    [SubstitutionType.BusinessTrip]: {
      icon: TripBig,
      text: 'В командировке',
      backgroundColor: themePalette.colorAccidentBlueDark,
    },
    [SubstitutionType.Vacation]: {
      icon: VacationBig,
      text: 'В отпуске',
      backgroundColor: themePalette.colorAccidentBlue2,
    },
  };
  const { icon, text, backgroundColor } = renderSubstitutionType[substitution.type];

  const dateRange = useMemo(() => {
    const isSameMonthInRange = isSameMonth(getValidDate(substitution.since), getValidDate(substitution.till));
    const isSameDayInRange = isSameDay(getValidDate(substitution.since), getValidDate(substitution.till));

    if (isSameDayInRange) {
      return getFormattedDate(substitution.since, 'd MMMM');
    }

    return `с ${getFormattedDate(
      substitution.since,
      isSameMonthInRange ? 'd' : 'd MMMM',
    )} по ${getFormattedDate(substitution.till, 'd MMMM')}`;
  }, [substitution.since, substitution.till]);

  return (
    <UiRow padding={[20, 24]} style={{ backgroundColor, color: themePalette.colorTextInvert }}>
      <UiSpace full size="large">
        <UiIcon width={40} height={40} component={icon} />
        <UiSpace size={0} direction="vertical">
          <UiTypography.Title level={2} style={{ marginBottom: 0, color: themePalette.colorTextInvert }}>
            {text}
          </UiTypography.Title>
          <UiTypography.Title level={2} style={{ marginBottom: 0, color: themePalette.colorTextInvert }}>
            {dateRange}
          </UiTypography.Title>
        </UiSpace>
      </UiSpace>
    </UiRow>
  );
};

export { AbsenceHeader };

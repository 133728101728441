import React, { useEffect, useState, ReactElement } from 'react';

import { Layout } from 'ant/components/layout/layout';
import { ErrorBoundary } from 'ant/components/widgets/ErrorBoundary';
import { DynamicRenderComponentType } from 'core-components/hocs';
import { WidgetModule } from 'core-components/types';
import { CreateUpdateBlogModalProps } from 'groups-frontend/components/blogs/create-blog-modal-content/CreateUpdateBlogAdapter';

export type DynamicModuleProps = WidgetModule | CreateUpdateBlogModalProps | Record<string, React.FC>;

export const DynamicRenderComponent: DynamicRenderComponentType<DynamicModuleProps> = (props) => {
  const { modulePath, moduleProps } = props;
  const [module, setModule] = useState<ReactElement | null>(null);
  const [isError, setIsError] = useState(false);

  const getDynamicModule = () => {
    import(/* webpackMode: "lazy", webpackChunkName: "[request]" */ `modules/${modulePath}`)
      .then(({ default: Widget }) => {
        setModule(<Widget {...moduleProps} />);
      })
      .catch((e) => {
        setModule(null);
        setIsError(true);
        // eslint-disable-next-line no-console
        console.error(e);
      });
  };

  useEffect(() => {
    getDynamicModule();
  }, [setModule, modulePath, moduleProps]);

  return <ErrorBoundary>{module || <Layout.Loading spinning={!isError} />}</ErrorBoundary>;
};

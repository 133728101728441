import classNames from 'classnames';
import { useStoreMap } from 'effector-react';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { EmployeeContacts } from 'ant/components/widgets/EmployeeContacts';
import { profileFullInfoStorage } from 'ant/store/profile';
import { KeycloakId } from 'ant/types/models/keycloak-user';
import { ProfileTimeslots } from 'components-frontend/components/profile-timeslots/ProfileTimeslots';
import { SkillsGroup } from 'components-frontend/components/skills/SkillsGroup';
import { ProfilePageProps } from 'profile-frontend/components/ProfilePage';
import { Absence } from 'profile-frontend/components/absence/Absence';
import { ProfileBreadcrumb } from 'profile-frontend/components/breadcrumb/ProfileBreadcrumb';
import { Gamification } from 'profile-frontend/components/gamification/Gamification';
import { Profile } from 'profile-frontend/components/profile/Profile';
import { UserAgreement } from 'profile-frontend/components/user-agreement/UserAgreement';

import styles from './ProfilePage.scss';
import { EmployeeContactsQRCard } from './employee-contact-qr-card/EmployeeContactsQRCard';

const defaultBreadcrumbs = [
  {
    path: '/',
    breadcrumbName: 'Главная',
  },
];

export const ProfilePage: FC<ProfilePageProps> = (props) => {
  const { ProfileTimeline, breadcrumbs = defaultBreadcrumbs } = props;
  const { id: userId = '' } = useParams<{ id: KeycloakId }>();

  const isUserActive = useStoreMap(profileFullInfoStorage.storage.store, ({ data }) =>
    Boolean(data?.main?.isActive),
  );

  return (
    <>
      <div className={styles.pageMain__horizontalContainer}>
        <div
          className={classNames(styles.pageMain__sectionContainer, styles.pageMain__sectionContainer_movedUp)}
        >
          <div className={styles.pageMain__bigSection}>
            <ProfileBreadcrumb breadcrumbs={breadcrumbs} />
            <div className={styles.pageMain__timeSlots}>
              <ProfileTimeslots isUserActive={isUserActive} />
            </div>
          </div>
          <div className={styles.pageMain__row}>
            <div className={styles.pageMain__infinityScroll}>
              <Profile />
              <ProfileTimeline />
            </div>
            <div className={styles.pageMain__rowWrapper}>
              {isUserActive && (
                <div className={styles.pageMain__column}>
                  <Absence />
                  <EmployeeContacts userId={userId} style={{ margin: 10, width: 386 }}>
                    <EmployeeContactsQRCard userId={userId} />
                  </EmployeeContacts>
                </div>
              )}
              <div className={styles.pageMain__column}>
                <SkillsGroup />
                <Gamification />
                {isUserActive && <UserAgreement />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

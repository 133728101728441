import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { refetchOnCreateUpdateMeetingEvent } from 'ant/store/calendar/api';
import { CalendarEndpoints } from 'ant/store/calendar/endpoints';
import { CalendarBusySlots } from 'ant/types/models/calendar';

export type FetchBusySlotsResponse = CalendarBusySlots[];

export type FetchBusySlotsParams = {
  userIds: string[];
  since: string;
  till: string;
};

export type GetBusySlotsStorageParams = {
  isSubscribedOnCreateUpdateFromHeader?: boolean;
};

export const getBusySlotsStorage = ({
  isSubscribedOnCreateUpdateFromHeader,
}: GetBusySlotsStorageParams = {}) => {
  const storage = abstractStorageFactory<
    FetchBusySlotsResponse,
    FetchBusySlotsResponse,
    FetchBusySlotsResponse,
    FetchBusySlotsParams
  >({
    endpointBuilder: () => CalendarEndpoints.freeBusy(),
    requestMethod: 'post',
    dataBuilder: ({ since, till, userIds }) => ({ since, till, userIds }),
    dataMapper: (data) => data,
    defaultValue: [],
  });

  if (isSubscribedOnCreateUpdateFromHeader) {
    storage.store.on(refetchOnCreateUpdateMeetingEvent, () => {
      storage.refetchWithLastParams();
    });
  }

  return { storage };
};

import React, { FC, useCallback } from 'react';

import DocumentSvg from 'ant/components/svg/document.svg';
import { UiCard } from 'ant/components/ui/card';
import { UiIcon } from 'ant/components/ui/icon';
import { UiTypography } from 'ant/components/ui/typography';
import { closeGlobalModal, openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';

export const UserAgreement: FC = () => {
  const onClickOpenUserAgreement = useCallback(() => {
    openGlobalModal(GlobalModalNames.ProfilePreviewUserAgreementCurrent, {
      onClose: () => closeGlobalModal(GlobalModalNames.ProfilePreviewUserAgreementCurrent),
    });
  }, []);

  return (
    <UiCard size="default" style={{ margin: 10 }}>
      <UiIcon.Label component={DocumentSvg}>
        <UiTypography.Link onClick={onClickOpenUserAgreement}>Пользовательское соглашение</UiTypography.Link>
      </UiIcon.Label>
    </UiCard>
  );
};

import React, { FC, Fragment } from 'react';

import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { ProfileBiographyEducationModel, ProfileVisibilityValues } from 'ant/types/models/profile.model';

type Props = {
  education: ProfileBiographyEducationModel;
  filterByVisible?: boolean;
};

export const InfoEducation: FC<Props> = (props) => {
  const { education, filterByVisible } = props;
  const visibilityValue = filterByVisible ? ProfileVisibilityValues.Visible : ProfileVisibilityValues.Hidden;
  const visibilityValueForType = education.additional.filter(
    ({ visibility }) => visibility === visibilityValue,
  );

  return visibilityValueForType.length ? (
    <UiTypography.Text strong>
      <UiTruncateMarkup truncate lines={20}>
        {visibilityValueForType.map(({ id, dateEnd, institution, specialization, qualification }) => (
          <Fragment key={id}>
            {[institution, specialization, qualification, dateEnd].filter((value) => value).join(', ')}
            <br />
          </Fragment>
        ))}
      </UiTruncateMarkup>
    </UiTypography.Text>
  ) : null;
};

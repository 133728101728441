import { AxiosResponse } from 'axios';

import { endpoints } from 'ant/endpoints';
import { api } from 'ant/plugins/api';
import { GamificationBadgeId } from 'ant/types/models/gamification.model';
import { KeycloakId } from 'ant/types/models/keycloak-user';

export type CreateThanksParams = {
  msg: string;
  toUser: KeycloakId;
  typeRid: string | number;
  coins?: number;
};

export type SendBadgeParams = {
  badge: GamificationBadgeId;
  user: KeycloakId;
  coins: number;
};

export const createThanks = <T = unknown>({
  msg,
  toUser,
  typeRid,
  coins,
}: CreateThanksParams): Promise<AxiosResponse<T>> =>
  api.post({
    url: endpoints.gamification.thanksCreate(),
    data: { msg, toUser, typeRid, coins },
  });

export const sendBadge = <T = unknown>(data: SendBadgeParams): Promise<AxiosResponse<T>> =>
  api.post({
    url: endpoints.gamification.badgesSend(),
    data,
  });

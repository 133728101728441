import { AxiosResponse } from 'axios';

import { CropImageParams } from 'ant/components/widgets/CropImageArea';
import { ProfileEndpoints } from 'ant/endpoints/profile';
import { api } from 'ant/plugins/api';
import { FileStorageEntryId } from 'ant/types/models/file.model';
import { KeycloakId } from 'ant/types/models/keycloak-user';
import { ProfileUserSettings } from 'ant/types/models/profile.model';

export const getProfileUserSettings = () => ProfileEndpoints.userSettings();

export type ProfileUserAvatarUploadParams = {
  userId: KeycloakId;
  fileId: FileStorageEntryId;
  settings: {
    medium: CropImageParams;
    small: CropImageParams;
  };
};

export const profileUserAvatarUpload = <T = unknown>({
  userId,
  fileId,
  settings,
}: ProfileUserAvatarUploadParams) =>
  api.post<T>({
    url: ProfileEndpoints.avatarCreate(userId),
    data: { fileId, settings },
  });

export const postProfileUserSettings = <T = unknown>(
  params: Partial<ProfileUserSettings>,
): Promise<AxiosResponse<T>> =>
  api.post({
    url: ProfileEndpoints.userSettings(),
    data: { settings: { ...params } },
  });

import React, { FC } from 'react';

import { UiImageBackground } from 'ant/components/ui/image-background';
import { UiModal } from 'ant/components/ui/modals';
import { UiSpace } from 'ant/components/ui/space';
import { UiTypography } from 'ant/components/ui/typography';
import { getModalStepsForSingleTitle } from 'ant/plugins/utils/get-modal-steps-for-single-title';
import { useTheme } from 'ant/theme/provider';
import {
  GamificationPostPropKey,
  GamificationTrophy,
  GamificationThankPostProps,
} from 'components-frontend/components/gamification/trophy/GamificationTrophy';
import patternAchievePng from 'profile-frontend/assets/images/pattern-achieve.png';

import { ThanksHeader } from './thanks-header/ThanksHeader';

export const BadgeThanksView: FC<GamificationThankPostProps> = (props) => {
  const [{ variables: themeVariables }] = useTheme();
  const { onClose } = props;
  let thank;
  let badge;

  if (GamificationPostPropKey.Thank in props) {
    const { thank: thankData } = props;

    thank = thankData;
  }

  if (GamificationPostPropKey.Badge in props) {
    const { badge: badgeData } = props;

    badge = badgeData;
  }

  const title = badge ? 'Награда' : 'Благодарность';

  return (
    <UiModal.Content style={{ backgroundColor: themeVariables.colorBrand08 }}>
      <UiImageBackground url={patternAchievePng} style={{ borderRadius: 8 }}>
        <UiModal.Header>
          <UiModal.Header.Title steps={getModalStepsForSingleTitle('')} style={{ padding: 0 }} />
        </UiModal.Header>
        <UiSpace flex align="center" direction="vertical" style={{ padding: 24 }}>
          <UiTypography.Title level={3} style={{ marginTop: 16 }}>
            {title}
          </UiTypography.Title>
          {thank && <ThanksHeader thank={thank} />}
          {thank && <GamificationTrophy thank={thank} onClose={onClose} />}
          {badge && <GamificationTrophy badge={badge} onClose={onClose} />}
        </UiSpace>
      </UiImageBackground>
    </UiModal.Content>
  );
};

import { useStore, useStoreMap } from 'effector-react';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import AddCircleSvg from 'ant/components/svg/add-circle.svg';
import Appreaction3Svg from 'ant/components/svg/appreaction-3.svg';
import ExploreSvg from 'ant/components/svg/explore.svg';
import VoteUpSvg from 'ant/components/svg/vote-up.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiCard } from 'ant/components/ui/card';
import { UiDivider } from 'ant/components/ui/divider';
import { UiList } from 'ant/components/ui/list';
import { UiModal, UiModalTypes } from 'ant/components/ui/modals';
import { UiSkeleton } from 'ant/components/ui/skeleton';
import { UiSpace } from 'ant/components/ui/space';
import { UiTooltip } from 'ant/components/ui/tooltip';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { useUnmount } from 'ant/helpers/hooks/use-unmount';
import { createArrayMock } from 'ant/plugins/create-mocks';
import { getRoutePath, RouteNames } from 'ant/plugins/router';
import { useCurrentProfile } from 'ant/providers/current-profile';
import { closeGlobalModal, openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import { profileFullInfoStorage } from 'ant/store/profile';
import { GamificationBadges, GamificationItemTypes } from 'ant/types/models/gamification.model';
import { KeycloakId } from 'ant/types/models/keycloak-user';
import { GamificationItem } from 'components-frontend/components/gamification/item/GamificationItem';

import {
  gamificationUserBadgesStorage,
  getGamificationUserThanksSummaryStorage,
} from '../../store/gamification';
import { AddBadge } from './add-badge/AddBadge';
import { AddThanks } from './add-thanks/AddThanks';
import { BadgeThanksView } from './badge-thanks-view/BadgeThanksView';
import { GamificationEmpty } from './empty/GamificationEmpty';
import { GamificationList } from './list/GamificationList';
import {
  GamificationModalTypes,
  getChosenUserBadgeStorage,
  getGamificationModalStorage,
  ToggleGamificationModalEvent,
} from './store';

const gamificationMocks = (
  <UiList
    split={false}
    grid={{ gutter: 16 }}
    dataSource={createArrayMock(4, (_, i) => i)}
    renderItem={() => (
      <UiList.Item>
        <UiSkeleton circle loading width={72} height={70} />
      </UiList.Item>
    )}
  />
);

type ModalProps = {
  size: UiModalTypes;
  onClose: () => void;
};

export const Gamification: FC = () => {
  const navigate = useNavigate();
  const { id: userId = '' } = useParams<{ id: KeycloakId }>();

  const { storage: userBadgesStorage } = gamificationUserBadgesStorage;
  const { gamificationModalStore, toggleGamificationModalEvent } = useMemo(getGamificationModalStorage, []);
  const { type: modalType, isOpen: isModalOpen } = useStore(gamificationModalStore);

  const isUserActive = useStoreMap(profileFullInfoStorage.storage.store, ({ data }) =>
    Boolean(data?.main?.isActive),
  );

  const toggleGamificationModalByType = useCallback(({ type }: ToggleGamificationModalEvent) => {
    return () => toggleGamificationModalEvent({ type });
  }, []);

  const isActiveGamificationModal = useCallback(
    (key: GamificationModalTypes) => key === modalType,
    [modalType],
  );

  const modalProps: Record<GamificationModalTypes, ModalProps> = useMemo(() => {
    return {
      [GamificationModalTypes.UserBadge]: {
        size: UiModalTypes.Medium,
        onClose: toggleGamificationModalByType({ type: GamificationModalTypes.UserBadge }),
      },
      [GamificationModalTypes.AddThanks]: {
        size: UiModalTypes.Medium,
        onClose: toggleGamificationModalByType({ type: GamificationModalTypes.AddThanks }),
      },
      [GamificationModalTypes.AddBadge]: {
        size: UiModalTypes.Medium,
        onClose: toggleGamificationModalByType({ type: GamificationModalTypes.AddBadge }),
      },
      [GamificationModalTypes.AllThanks]: {
        size: UiModalTypes.Large,
        onClose: toggleGamificationModalByType({ type: GamificationModalTypes.AllThanks }),
      },
    };
  }, [toggleGamificationModalByType]);

  const { chosenUserBadgeStore, updateChosenUserBadgeEvent } = useMemo(getChosenUserBadgeStorage, []);
  const chosenUserBadgeState = useStore(chosenUserBadgeStore);

  const userThanksStorage = useMemo(() => getGamificationUserThanksSummaryStorage({ userId }), [userId]);

  const { data: userBadgesItems, loading: isBadgesLoading } = useAbstractStorage(userBadgesStorage, {
    autoFetchAndRefetch: true,
    resetStoreOnUnmount: true,
    autoFetchParams: {
      userId,
    },
  });

  const userBadges = useMemo(() => [...(userBadgesItems?.items || [])], [userBadgesItems]);

  const { data: userThanks, loading: isThanksLoading } = useAbstractStorage(userThanksStorage.storage, {
    autoFetchAndRefetch: true,
    resetStoreOnUnmount: true,
  });

  const profile = useCurrentProfile();
  const isCurrentProfileSuperAdmin = Boolean(profile.superAdmin);
  const isOwner = userId === profile.id;

  const userThanksIsEmpty = !isThanksLoading && !userThanks.length;

  const onUserBadgeClick = useCallback(
    (badge: GamificationBadges) => () => {
      toggleGamificationModalEvent({ type: GamificationModalTypes.UserBadge });
      updateChosenUserBadgeEvent(badge);
    },
    [],
  );

  const onOpenRewardProgress = () => {
    navigate(getRoutePath(RouteNames.ProfileRewardsProgress, { id: userId }));
  };

  useEffect(() => {
    if (isActiveGamificationModal(GamificationModalTypes.AddThanks)) {
      const unsubscribe = userThanksStorage.createThanksEffect.doneData.watch((thank) => {
        openGlobalModal(GlobalModalNames.ProfileBadgeThanksView, {
          onClose: () => closeGlobalModal(GlobalModalNames.ProfileBadgeThanksView),
          thank,
        });

        return unsubscribe;
      });
    }
  }, [modalType]);

  useUnmount(userBadgesStorage.resetStoreEvent);

  return (
    <UiCard size="default" emptyPadding style={{ margin: 10, width: 386 }}>
      <UiCard.Header>
        <UiCard.Header.Title>Награды</UiCard.Header.Title>
        <UiSpace size={16} style={{ marginLeft: 'auto' }}>
          {isCurrentProfileSuperAdmin && isUserActive && (
            <UiTooltip placement="top" title="Наградить">
              <UiButton
                type="link-secondary"
                icon={<AddCircleSvg />}
                onClick={toggleGamificationModalByType({ type: GamificationModalTypes.AddBadge })}
              />
            </UiTooltip>
          )}

          <UiTooltip placement="left" title="Перейти в список наград">
            <UiButton onClick={onOpenRewardProgress} type="link-secondary" icon={<ExploreSvg />} />
          </UiTooltip>
        </UiSpace>
      </UiCard.Header>

      <UiCard.Content basePadding={false} style={{ padding: '0 24px 8px' }}>
        {isBadgesLoading && gamificationMocks}
        {((!isBadgesLoading && !userBadges.length) || !isUserActive) && (
          <GamificationEmpty type={GamificationItemTypes.Badge} style={{ marginBottom: 16 }} />
        )}
        {!isBadgesLoading && userBadges.length > 0 && isUserActive && (
          <UiList
            split={false}
            grid={{ gutter: 16, column: 4 }}
            dataSource={userBadges}
            rowKey={({ id }) => id}
            renderItem={(badge) => (
              <UiList.Item onClick={onUserBadgeClick(badge)}>
                <GamificationItem image={badge.badge.image} icon={Appreaction3Svg} />
              </UiList.Item>
            )}
          />
        )}
      </UiCard.Content>

      <UiDivider emptyMargin />

      <UiCard.Header>
        <UiCard.Header.Title>Благодарности</UiCard.Header.Title>
        {!isOwner && isUserActive && (
          <UiTooltip placement="left" title="Поблагодарить">
            <UiButton
              style={{ marginLeft: 'auto' }}
              type="link-secondary"
              icon={<AddCircleSvg />}
              onClick={toggleGamificationModalByType({ type: GamificationModalTypes.AddThanks })}
            />
          </UiTooltip>
        )}
      </UiCard.Header>

      <UiCard.Content basePadding={false} style={{ padding: '0 24px 24px' }}>
        {isThanksLoading && gamificationMocks}
        {!userThanksIsEmpty && userThanks.length > 0 && isUserActive && (
          <UiList
            split={false}
            grid={{ gutter: 16, column: 4 }}
            dataSource={userThanks}
            rowKey={({ name }) => name}
            renderItem={(thank) => (
              <UiList.Item>
                <GamificationItem tooltipTitle={thank.name} image={thank.image} icon={VoteUpSvg} />
              </UiList.Item>
            )}
          />
        )}
        {!userThanksIsEmpty && isUserActive && (
          <UiButton
            type="link"
            label="Все благодарности"
            onClick={toggleGamificationModalByType({ type: GamificationModalTypes.AllThanks })}
          />
        )}
        {(userThanksIsEmpty || !isUserActive) && (
          <GamificationEmpty
            type={isUserActive ? GamificationItemTypes.Thanks : GamificationItemTypes.Badge}
            toggleAddThanksOpen={toggleGamificationModalByType({ type: GamificationModalTypes.AddThanks })}
          />
        )}
      </UiCard.Content>

      <UiModal onClose={modalProps[modalType].onClose} type={modalProps[modalType].size} isOpen={isModalOpen}>
        {isActiveGamificationModal(GamificationModalTypes.AllThanks) && <GamificationList />}
        {isActiveGamificationModal(GamificationModalTypes.AddThanks) && (
          <AddThanks userThanksStorage={userThanksStorage} />
        )}
        {isActiveGamificationModal(GamificationModalTypes.AddBadge) && (
          <AddBadge userBadgesStorage={gamificationUserBadgesStorage} />
        )}
        {isActiveGamificationModal(GamificationModalTypes.UserBadge) && chosenUserBadgeState && (
          <BadgeThanksView badge={chosenUserBadgeState} />
        )}
      </UiModal>
    </UiCard>
  );
};

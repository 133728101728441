import { Registry, useRegistry, withRegistry } from '@bem-react/di';
import rusLocale from 'antd/lib/locale/ru_RU';
import React, { FC, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { UiConfigProvider, UiConfigProviderProps } from 'ant/components/ui/config-provider';
import { CurrentProfileProvider } from 'ant/providers/current-profile';
import { SettingsConfigService } from 'ant/providers/settings-config';
import { ThemeService } from 'ant/theme/service/ThemeService';
import { KeycloakId } from 'ant/types/models/keycloak-user';
import { UserModelId } from 'ant/types/models/user.model';

const App: FC<UiConfigProviderProps> = (props) => {
  const { Router, AuthService } = useRegistry('pages-frontend');
  const [userId, setUserId] = useState<UserModelId<KeycloakId>>();

  return (
    <UiConfigProvider locale={rusLocale} {...props}>
      <SettingsConfigService>
        <BrowserRouter>
          <AuthService setUserId={setUserId}>
            <CurrentProfileProvider userId={userId}>
              <ThemeService>
                <Router />
              </ThemeService>
            </CurrentProfileProvider>
          </AuthService>
        </BrowserRouter>
      </SettingsConfigService>
    </UiConfigProvider>
  );
};

export const runApp = (registries: Registry[], configProps?: UiConfigProviderProps) => {
  const AppWithRegistry = withRegistry(...registries)(App);

  const container = document.getElementById('root');
  const root = createRoot(container!);

  root.render(<AppWithRegistry {...configProps} />);
};
